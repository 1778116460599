import {Component, Inject, OnInit} from '@angular/core';
import {ActionSource} from '../../services/yeti-protocol/tracking';
import {LAST_URL, LOCAL_STORAGE_SERVICE, StorageService} from '../../services/storage/storage.service';
import {CONTEXT_SERVICE, ContextService} from '../../services/context/context.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-no-internet-connection',
  templateUrl: './no-internet-connection.page.html',
  styleUrls: ['./no-internet-connection.page.scss'],
})
export class NoInternetConnectionPage implements OnInit {

  constructor(
    @Inject(LOCAL_STORAGE_SERVICE) private storage: StorageService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  async tryAgain(): Promise<any> {

    let url = `${this.contextService.currentContext.homePath}/feed?source=${this.source}`;

    try {
      const lastUrl = await this.storage.get(LAST_URL);

      if (lastUrl?.length && lastUrl?.indexOf('/maintenance') === -1 && lastUrl?.indexOf('/no-internet-connection') === -1) {
        url = lastUrl.indexOf('?') !== -1 ? `${lastUrl}&source=${this.source}` : `${lastUrl}?source=${this.source}`;
      }
    } catch (err) {
      console.error(err);
    }

    return this.router.navigateByUrl(url, {replaceUrl: true});
  }

  get source(): ActionSource {
    return ActionSource.noInternetPage;
  }

}
