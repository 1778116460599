import { Component, Inject, Input } from '@angular/core';
import { Post, PostListItem, PostType } from '../../services/yeti-protocol/chatter-api';
import { GalleryConfig, ReviewGalleryModeData } from 'src/app/modules/file-select/services/image-gallery.model';

// services
import { LinkOpenerService } from '../../services/link-opener.service';
import { AttachmentMimeType, ImageAttachment, isAnyImage, isVideo } from 'src/app/services/attachments.model';
import { ImageGalleryService } from 'src/app/modules/file-select/services/image-gallery.service';
import { FileSelectScope } from 'src/app/modules/file-select/services/file-select.service';
import { UIUtilsServiceInterface, UI_UTILS_SERVICE } from 'src/app/services/utils/ui-utils.service.interface';
import { getImagesWithCorrectUrlBasedOnWatermark } from '../../services/utils/image-attachments.utils';

@Component({
  selector: 'app-post-card-attachments',
  templateUrl: './post-card-attachments.component.html',
  styleUrls: ['./post-card-attachments.component.scss'],
})
export class PostCardAttachmentsComponent {

  @Input() post: PostListItem | Post;
  @Input() isOwner = false;
  @Input() preventGalleryOpen: boolean;
  @Input() pendingUser = false;
  postType = PostType;
  AttachmentMimeType = AttachmentMimeType;

  constructor(
    private linkOpener: LinkOpenerService,
    private imageGalleryService: ImageGalleryService,
    @Inject(UI_UTILS_SERVICE) public uiUtilsService: UIUtilsServiceInterface,
  ) { }

  openGalleryModal(): void {
    const galleryConfig: GalleryConfig = {
      images: this.imageAttachments,
      allowImageEdit: this.isOwner,
      reviewMode: this.isOwner,
      scope: FileSelectScope.POST,
      imageGalleryStrategy: null
    }

    this.imageGalleryService.openImageGallery(galleryConfig)
      .then(res => {
        if (res) {
          this._updateImageAttachments(res);
        }
      });
  }

  _updateImageAttachments(data: ReviewGalleryModeData): void {
    if (data && data.images) {
      const imagesMap: Record<string, ImageAttachment> = {};
      data.images.forEach(image => {
        imagesMap[image._id] = image;
      });
      this.imageAttachments.forEach(attachment => {
        if (isAnyImage(attachment.mimeType)) {
          const dataImage = imagesMap[attachment._id];
          if (dataImage) {
            attachment.watermarkedPreviewUrl = dataImage.watermarkedPreviewUrl;
            attachment.fullWatermarkUrl = dataImage.fullWatermarkUrl;
            attachment.fullUrl = dataImage.fullUrl;
            attachment.previewUrl = dataImage.previewUrl;
            attachment.videoUrl = dataImage.videoUrl;
          }
        }
      });
    }
  }

  get hasDocumentAttachment(): boolean {
    return this.post && this.post.type === PostType.attachment
      && this.post?.attachments?.length > 0
      && this.post?.attachments[this.post?.attachments?.length - 1]?.mimeType === AttachmentMimeType.PDF;
  }

  get hasOpenGraphInfo(): boolean {
    return !!(this.post && this.post.type === PostType.link && this.post.og);
  }

  get hasImageAttachments(): boolean {
    return this.post?.type === PostType.attachment && this.imageAttachments.length > 0;
  }

  openLink(link: string): void {
    if (this.pendingUser) {
      this.uiUtilsService.showPendingStateExplainerDialog(this.post?._id, this.post?.type);
      return;
    }
    this.linkOpener.open(link);
  }

  openDocumentLink(link: string): void {
    if (this.pendingUser) {
      this.uiUtilsService.showPendingStateExplainerDialog(this.post?._id, this.post?.type);
      return;
    }

    this.linkOpener.openDocumentLink(link);
  }

  get imageAttachments(): Array<ImageAttachment> {
    const imageAttachments = this.post.attachments.filter(attachment => {
      return attachment.mimeType?.indexOf('image') > -1 || isVideo(attachment.mimeType);
    })
    return getImagesWithCorrectUrlBasedOnWatermark(
      imageAttachments,
      !this.post?.isAnonymous && this.post?.watermarked,
      this.post?.isAnonymous);
  }

  protected readonly console = console;
}
