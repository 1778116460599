import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// models
import { Article } from '../../services/my-stream.model';
import {
  ActionSheetConfig,
  ArticleActionsVisibility,
  MiCardActionsObjectType,
  ArticleActions
} from '../../services/mi-card-actions.service';

// services
import { SharingUIService } from 'src/app/services/sharing/sharing-ui.service';

import {
  ItemTypes,
  RecommendationsBookmarksService
} from '../../services/recommendations-service/recommendations-bookmarks.service';
import {ToastMode, ToastService} from '../../services/toast.service';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import { AuthService } from '../../services/auth/auth.service';
import {ActionSource, TranslationAction} from '../../services/yeti-protocol/tracking';
import { ContextService, CONTEXT_SERVICE } from 'src/app/services/context/context.model';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';
import {ConfirmDialogData, CONTEXT_DIALOGS_UI, ContextDialogsUI} from 'src/app/services/dialogs/dialogs.ui.interface';
import { ArticleService } from 'src/app/services/article/article.service';
import {TranslatorData, TranslatorObject} from '../../services/translator.model';
import {TranslatorService} from '../../services/translator.service';

@Component({
  selector: 'app-article-card-extended',
  templateUrl: './article-card-extended.component.html',
  styleUrls: ['./article-card-extended.component.scss'],
})
export class ArticleCardExtendedComponent implements OnInit {

  @Input() article: Article;
  @Input() source: ActionSource;

  miCardActionsActionSheetConfig: ActionSheetConfig
  articleActionsVisibility: ArticleActionsVisibility
  safeVideoUrl: any;
  hideArticleImage: boolean;
  user: UserProfile;

  isTranslated = false;
  originalArticleTitle: string;

  private bookmarking: boolean;

  constructor(
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private recommendationsService: RecommendationsBookmarksService,
    private toast: ToastService,
    private authService: AuthService,
    private sharingUIService: SharingUIService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private dialogsUiService: DialogsUIService,
    private articleService: ArticleService,
    @Inject(CONTEXT_DIALOGS_UI) private dialogs: ContextDialogsUI,
    private translatorService: TranslatorService
  ) { }

  ngOnInit(): void {
    this.authService.userProfileAsObservable.subscribe((userProfile: UserProfile) => {
      this.user = userProfile;
    });

    this.articleActionsVisibility = {
      bookmark: true,
      translate: true
    };

    this.miCardActionsActionSheetConfig = {
      objectType: MiCardActionsObjectType.article,
      object: this.article,
      actionsVisibilityConfig: this.articleActionsVisibility
    }
  }

  openArticle(): void {
    const url = '/' + [this.contextService.currentContext.key, 'article', this.article?._id].join('/');
    if (this.source) {
      this.router.navigate([url], { queryParams: { source: this.source } });
    } else {
      this.router.navigateByUrl(url);
    }
  }

  onShareArticle(): void {
    this.sharingUIService.showShareDialogForObject(this.article);
  }

  miCardActionsActionClicked(action: string): void {
    switch (action) {
      case ArticleActions.bookrmark:
        this.bookmark();
        break;
      case ArticleActions.translate:
        this.onTranslate();
        break;
    }
  }

  onTranslate(): void {
    const translationLanguage = this.user.translationLanguage || '';
    if (translationLanguage) {
      this.translateArticle(translationLanguage)
    } else {
      this.dialogs.showSelectLanguageDialog();
    }
  }

  translateArticle(translationLanguage: string): void {
    if (this.isTranslated) {
      this.article.title = this.originalArticleTitle;
      this.isTranslated = false;
    } else {
      this.originalArticleTitle = this.article.title;
      const dataToTranslateArray: Array<TranslatorObject> = new Array<TranslatorObject>();
      dataToTranslateArray.push({
        origin: '',
        title: '',
        text: this.article?.title
      });
      this.trackTranslationAction(this.article?._id, this.article?.title, translationLanguage, TranslationAction.translate);
      this.translatorService.translateBatch('google', translationLanguage, dataToTranslateArray)
        .then((data: TranslatorData) => {
          if (data.records.length === 1) {
            this.article.title = data.records[0].text;
            this.isTranslated = true;
          }
        }).catch(err => {
        if (err?.error?.error?.message?.errfor?.message) {
          this.showErrorMessage(err?.error?.error?.message?.errfor?.message);
        }
      });
    }

  }

  trackTranslationAction(articleId: string, articleTitle: string, language: string, action: string): void {
    this.trackingService.trackTranslateAction(articleId, 'article', articleTitle, language, action)
      .catch(_err => {
        console.error('Could not track translation click action.');
      });
  }

  async bookmark(): Promise<void> {

    if (this.bookmarking) {
      return;
    }

    this.trackBookmarkClicked();

    if (this.article?.hasBookmarked) {
      const shouldProceed = await this.showShouldRemoveSavedItemDialog();

      if (!shouldProceed) {
        return;
      }

      this.bookmarking = true;

      this.recommendationsService.
        removeRecommendationBookmarkItem(this.article?.type, ItemTypes.BOOKMARK, this.article._id)
        .then(response => {
          if (response && response?.success) {
            this.article.hasBookmarked = !this.article.hasBookmarked;
            this.articleService.emitArticleUpdated(this.article as any);
            this.toast.show('app.common.item-removed-from-my-saved-knowledge');
          }
        }).catch(err => {
          console.error(err);
          this.toast.show('app.common.remove-saved-item-error-message');
        }).finally(() => {
          this.bookmarking = false;
        });
    } else {
      this.bookmarking = true;

      this.recommendationsService.addRecommendationBookmarkItem(
        this.article?.type, ItemTypes.BOOKMARK,
        this.article._id)
        .then(response => {
          if (response && response?.success) {
            this.article.hasBookmarked = !this.article.hasBookmarked;
            this.articleService.emitArticleUpdated(this.article as any);
            this.toast.show('app.common.item-added-to-my-saved-knowledge');
          }
        }).catch(err => {
          console.error(err);
          this.toast.show('app.common.save-item-error-message');
        }).finally(() => {
          this.bookmarking = false;
        });
    }
  }

  recommendClicked(article: Article): void {
    this.recommendationsService.recommendClicked(article, this.user)
  }

  trackBookmarkClicked(): void {
    this.trackingService.trackGenericClickedAction(this.article._id, this.article?.type, 'save',
      { source: this.source || ActionSource.unspecified })
      .catch(_err => {
        console.error('Could not track bookmark click action.');
      });
  }

  private async showShouldRemoveSavedItemDialog(): Promise<boolean> {

    const removeActionKey = 'remove';
    const cancelActionKey = 'cancel';

    const showShouldRemoveSavedItemDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: 'app.common.remove-saved-item-confirm.title'
        },
        message: {
          translationKey: 'app.common.remove-saved-item-confirm.text'
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey: 'app.common.remove-saved-item-confirm.cancel-btn'
            },
            className: 'secondary'
          },
          {
            key: removeActionKey,
            label: {
              translationKey: 'app.common.remove-saved-item-confirm.confirm-btn'
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogsUiService.createConfirmDialog(showShouldRemoveSavedItemDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === removeActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }

  showErrorMessage(message: string): void {
    this.toast.showWithMessage(message, 'app.common.error-default', ToastMode.ERROR);
  }
}
