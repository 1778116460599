/* eslint no-bitwise: ["error", { "allow": ["&", "|"] }] */
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { Device } from '@awesome-cordova-plugins/device/ngx';

import { LOCAL_STORAGE_SERVICE, StorageService } from '../storage/storage.service';
import { AuthService } from '../auth/auth.service';

import {
  ActionSource,
  ActionTracked,
  GenericTrackingParam,
  ImpressionTrackingRequest,
  TrackingRequest,
  TranslationTrackingParam
} from '../yeti-protocol/tracking';
import { contextForServer, ContextKey } from '../../contexts/utils';
import { Platform } from 'src/config/config.model';
import { generateUUID } from './random';

import appConfig from 'src/config/config';
import { ContextService, CONTEXT_SERVICE } from '../context/context.model';
import { TrackingService, TrackingSourceParams } from './tracking.model';
import { isMobilePlatform } from '../utils/utils';

const UUID_KEY = 'uuid-key';

const pathsMapping = [
  { substring: 'feed', source: ActionSource.feedPage, type: 'page' },
  { substring: 'casefolio', source: ActionSource.caseFolioPage, type: 'page' },
  { substring: 'groups', source: ActionSource.groupsPage, type: 'page' },
  { substring: 'group/case', source: ActionSource.groupCasePage, type: 'case' },
  { substring: 'group/post', source: ActionSource.groupPostPage, type: 'post' },
  { substring: 'group', source: ActionSource.groupPage, type: 'page' },
  { substring: 'contacts', source: ActionSource.contactsPage, type: 'page' },
  { substring: 'activity', source: ActionSource.activityPage, type: 'page' },
  { substring: 'events', source: ActionSource.eventsPage, type: 'page' },
  { substring: 'event/case', source: ActionSource.eventCasePage, type: 'case' },
  { substring: 'event/post', source: ActionSource.eventPostPage, type: 'post' },
  { substring: 'event', source: ActionSource.eventPage, type: 'page' }
]

function contextForTracking(contextKey: string, platform: Platform): string {
  const isMobile = isMobilePlatform(platform);
  let trackingKey = contextForServer(contextKey);
  if (isMobile) {
    if (trackingKey === ContextKey.orthopedics) {
      trackingKey = ContextKey.ort;
    }
    trackingKey = `${trackingKey}-${platform}`;
  }
  return trackingKey;
}

interface TrackingServiceConfig {
  drywallServer: string;
  platform: Platform;
  clientId: string;
}

@Injectable()
export class TrackingServiceImpl implements TrackingService {

  config: TrackingServiceConfig = {
    ...appConfig,
    clientId: appConfig.authentication.clientId,
  };
  uuid: string = null;
  _generateUUID = generateUUID;
  _source: string;
  _sourceCampaign: string;

  constructor(
    private route: ActivatedRoute,
    private device: Device,
    private httpClient: HttpClient,
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    @Inject(LOCAL_STORAGE_SERVICE) public uuidStorage: StorageService,
  ) {
    // TODO: make UUID storage an optional service
    this.route.queryParams.subscribe(queryParams => {
      this._processSourceParams(queryParams);
    });
  }

  track(data: TrackingRequest): Promise<void> {
    if (!data) {
      // there is nothing to track ...
      return Promise.resolve();
    }

    if (data.params) {
      data.params.platform = this.config.platform.toString();
      data.params.isIonic = true;
    }

    if (this._sourceCampaign || this._source) {
      if (!data.params) {
        data.params = {};
      }
      data.params.sourceCampaign = {};
      if (this._source) {
        data.params.sourceCampaign.source = this._source;
        if (!data.params.source) { // only if not already set
          data.params.source = this._source;
        }
      }
      if (this._sourceCampaign) {
        data.params.sourceCampaign.marketingCampaign = this._sourceCampaign;
        data.params.marketingCampaign = this._sourceCampaign;
      }
    }
    return this._sendTrackingRequest(data);
  }

  /**
   * Use when we need to track generic action which will be indexed with verb: clicked
   * @param objectId
   * @param objectType
   * @param objectTitle
   * @param sourceParams: optional - if provided will overwrite the source of the action to be tracked
   */
  trackGenericClickedAction(objectId: string, objectType: string, objectTitle?: string,
    sourceParams?: TrackingSourceParams): Promise<void> {
    this._processSourceParams(sourceParams);
    const paramsToTrack: GenericTrackingParam = {
      objectId: objectId,
      objectType: objectType,
      objectTitle: objectTitle
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.clicked,
      params: paramsToTrack
    };
    return this.track(trackData).catch(_err => {
      console.error('Something went wrong on generic click action: ' + _err);
    });
  }

  /**
   * Use when we need to track translated action
   * @param objectId - the myAO id of the object translated
   * @param objectType - type of content translated (article, video, post, case, ...)
   * @param objectTitle - title
   * @param sourceParams: optional - if provided will overwrite the source of the action to be tracked
   */
  trackTranslateAction(objectId: string, objectType: string, objectTitle: string,
    toLanguage: string, action: string, awsPersonalisationId?: string,
    sourceParams?: TrackingSourceParams): Promise<void> {
    this._processSourceParams(sourceParams);
    const paramsToTrack: TranslationTrackingParam = {
      objectId: objectId,
      objectType: objectType,
      objectTitle: objectTitle,
      toLanguage: toLanguage,
      action: action
    };

    const trackData: TrackingRequest = {
      action: ActionTracked.translated,
      params: paramsToTrack
    };
    if (awsPersonalisationId) {
      trackData.awsPersonalisationId = awsPersonalisationId;
    }
    return this.track(trackData).catch(_err => {
      console.error('Something went wrong while tracking translated action: ' + _err);
    });
  }

  /**
   * Use when we need to track generic pages loaded which will be indexed with elk verb: viewed
   * Generic pages to be loaded: authentication, Feed, Casefolio, Groups, Contacts, Activity, Events
   * objectType: static: pageLoaded
   * @param objectId: feed | casefolio | groups | contacts | activity | events
   * @param objectTitle: title of generic page opened
   * @param scope: why the page is opened usually used for tracking maintenance page.
   * @param reference: url because the page is opened usually used for tracking maintenance page.   */
  trackGenericPageOpenedAction(objectId: string, objectTitle: string, scope?: string, reference?: string): Promise<void> {
    const objectType = 'pageLoaded';
    const paramsToTrack: GenericTrackingParam = {
      objectId: objectId,
      objectType: objectType,
      objectTitle: objectTitle,
      scope,
      reference
    };
    const trackData: TrackingRequest = {
      action: ActionTracked.pageOpened,
      params: paramsToTrack
    };

    return this.track(trackData).catch(_err => {
      console.error('Something went wrong while tracking generic page opened action: ' + _err);
    });
  }

  /**
   * Use when we need to track generic modal opened
   * objectType: static: modalLoaded
   * @param objectId: pptUploaded
   * @param objectTitle: title of generic page opened
   */
  trackGenericModalOpenedAction(objectId: string, objectTitle: string): Promise<void> {
    const objectType = 'modalLoaded';
    const paramsToTrack: GenericTrackingParam = {
      objectId: objectId,
      objectType: objectType,
      objectTitle: objectTitle
    };
    const trackData: TrackingRequest = {
      action: ActionTracked.pageOpened,
      params: paramsToTrack
    };

    return this.track(trackData).catch(_err => {
      console.error('Something went wrong while tracking generic modal opened action: ' + _err);
    });
  }

  trackListItemImpression(data: ImpressionTrackingRequest): Promise<void> {
    if (!data) {
      // there is nothing to track ...
      return Promise.resolve();
    }

    data.platform = this.config.platform.toString();
    data.isIonic = true;

    return this._sendImpressionTrackingRequest(data);
  }

  mapPathToActionSource(currentPath: string): ActionSource {
    if (!currentPath) {
      return;
    }

    const foundPath = pathsMapping.find(path => {
      if (path.type === 'case' || path.type === 'case') {
        return currentPath.includes(path.substring);
      } else if (path.type === 'page') {
        return currentPath.includes(path.substring);
      }
    });

    if (!foundPath) {
      return;
    }
    return foundPath.source;
  }

  _processSourceParams(sourceParams: TrackingSourceParams): void {
    if (sourceParams) {
      if (sourceParams.source) {
        this._source = sourceParams.source;
      }
      if (sourceParams.campaign) {
        this._sourceCampaign = sourceParams.campaign;
      }
      // MYAO-5847: Pardot-triggered AO campaigns support
      if (sourceParams.utm_source) {
        this._source = sourceParams.utm_source;
      }
      if (sourceParams.utm_campaign) {
        this._sourceCampaign = sourceParams.utm_campaign;
      }
    }
  }

  _sendTrackingRequest(data: TrackingRequest): Promise<void> {
    return this.authService.isSignedIn()
      .then(async isSignedIn => {
        let appId;
        // if appId was already provided, do not calculate again
        if (data.appId) {
          appId = data.appId;
        } else {
          appId = await this._getAppIdToTrack(isSignedIn);
        }
        const trackUrl = `${this.config.drywallServer}track`;
        if (isSignedIn) {
          data.appId = appId;
          this._source = '';
          this._sourceCampaign = '';
          return this.authService.securePost(trackUrl, data);
        } else {
          return this._getUUID()
            .then(uuid => {
              if (uuid) {
                data.anonymId = uuid;
                data.appId = appId;
              }
              return this.httpClient.post(trackUrl, data);
            });
        }
      })
      .then(obs => {
        return firstValueFrom(obs)
          .then(() => {
            return Promise.resolve();
          })
          .catch(err => {
            console.log(err);
            throw err;
          });
      });
  }

  _sendImpressionTrackingRequest(data: ImpressionTrackingRequest): Promise<void> {
    return this.authService.isSignedIn()
      .then(async isSignedIn => {
        const trackUrl = `${this.config.drywallServer}impression/track`;
        if (isSignedIn) {

          if (!data.appId) {
            data.appId = await this._getAppIdToTrack(isSignedIn);
          }

          return this.authService.securePost(trackUrl, data);
        } else {
          return Promise.reject();
        }
      })
      .then(obs => {
        return firstValueFrom(obs)
          .then(() => {
            return Promise.resolve();
          })
          .catch(err => {
            console.log(err);
            throw err;
          });
      });
  }

  _getUUID(): Promise<string> {
    if (this.device.uuid) {
      return Promise.resolve(this.device.uuid);
    }
    // browser custom generated uuid
    if (this.uuid) {
      return Promise.resolve(this.uuid);
    }
    if (this.uuidStorage) {
      return this.uuidStorage.get(UUID_KEY)
        .then(uuid => {
          if (uuid) {
            this.uuid = uuid;
            return uuid;
          }
          this.uuid = this._generateUUID();
          return this.uuidStorage.set(UUID_KEY, this.uuid)
            .then(() => {
              return this.uuid;
            });
        });
    }
    this.uuid = this._generateUUID();
    return Promise.resolve(this.uuid);
  }

  async _getAppIdToTrack(signedIn: boolean): Promise<string> {
    let key = this.config.clientId;
    if (signedIn) {

      try {
        key = (await this.contextService.getCurrentContext())?.key;
      } catch (err) {
        key = this.contextService.currentContext.key;
      } finally {
        if (!key) {
          key = this.contextService.currentContext.key;
        }
      }
    }
    return contextForTracking(key, this.config.platform);
  }
}
