import {Injectable} from '@angular/core';
import {Network} from '@awesome-cordova-plugins/network/ngx';
import {BehaviorSubject, fromEvent, mapTo, merge, Observable} from 'rxjs';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private networkStatus = new BehaviorSubject<string>('unknown');

  constructor(
    private network: Network,
    private platform: Platform
  ) {
    this.platform.ready().then(() => {
      this.setInitialNetworkStatus();
      this.initializeNetworkEvents();
    });
  }

  private setInitialNetworkStatus() {
    if (this.platform.is('cordova')) {
      // Cordova environment
      const connectionType = this.network.type; // Get current connection type
      if (connectionType === 'none' || connectionType === 'unknown') {
        this.networkStatus.next('offline');
      } else {
        this.networkStatus.next('online');
      }
    } else {
      // Browser environment
      const isOnline = navigator.onLine; // Check browser's online status
      this.networkStatus.next(isOnline ? 'online' : 'offline');
    }
  }

  private initializeNetworkEvents() {
    if (this.platform.is('cordova')) {
      this.network.onDisconnect().subscribe(() => {
        this.networkStatus.next('offline');
      });

      this.network.onConnect().subscribe(() => {
        setTimeout(() => {
          if (this.network.type === 'unknown' || this.network.type === 'none') {
            this.networkStatus.next('poor');
          } else {
            this.networkStatus.next('online');
          }
        }, 3000); // Allow time for the connection to stabilize
      });
    } else {
      // Use browser events for web
      const online$ = fromEvent(window, 'online').pipe(mapTo('online'));
      const offline$ = fromEvent(window, 'offline').pipe(mapTo('offline'));

      merge(online$, offline$)
        .subscribe((status) => this.networkStatus.next(status));
    }

  }

  getNetworkStatus(): Observable<string> {
    return this.networkStatus.asObservable();
  }

  isOnline(): boolean {
    return this.networkStatus.getValue() === 'online';
  }
}
