import { AfterViewInit, Component, HostBinding, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { IonSegment, ModalController } from '@ionic/angular';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { UserProfile } from 'src/app/services/yeti-protocol/auth/mi';
import { TabConfig, TabIds, TabType } from 'src/config/config.model';

import { AuthService } from 'src/app/services/auth/auth.service';
import { CONTEXT_SERVICE, ContextService } from 'src/app/services/context/context.model';
import { QRCodeService } from 'src/app/services/qr-code/qr-code.service';
import { UserImageService } from 'src/app/services/user-image-service/user-image.service';
import { TRACKING_SERVICE, TrackingService, TrackingSourceParams } from 'src/app/services/tracking/tracking.model';
import { ResponsiveUtilsService } from 'src/app/services/utils/responsive-utils.service';
import { AppNavController } from 'src/app/services/app-nav-controller.service';

import appConfig from 'src/config/config';
import { GlobalSearchComponent } from '../search/global-search/global-search.component';
import { ConnectionRequestsService } from 'src/app/services/messenger/connection-requests.service';
import { ClinicalCaseService } from 'src/app/services/case-library/clinical-case.service';
import { SearchDialogComponent } from 'src/app/dialogs/search-dialog/search-dialog.component';
import { GlobalSubjects, GlobalSubjectsService } from 'src/app/services/utils/global-subjects.service';
import { Store } from '@ngxs/store';
import { ClinicalCasesAccessRequestsState } from 'src/app/state/clinical-cases-access-requests/clinical-cases-access-requests.state';
import { PendingConnectionRequestsSubState } from 'src/app/state/pending-connection-requests/pending-connection-requests.model';
import { PendingConnectionRequestsState } from 'src/app/state/pending-connection-requests/pending-connection-requests.state';
import { ActiveConversationsStateModel } from 'src/app/state/active-conversations/active-conversations.model';
import { ActiveConversationsState } from 'src/app/state/active-conversations/active-conversations.state';
import { ActivitiesState } from 'src/app/state/activities/activities.state';
import { ClinicalCasesState } from 'src/app/state/clinical-cases/clinical-cases.state';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('segment') buttonsSegment: IonSegment;
  @ViewChild('globalSearch') globalSearch: GlobalSearchComponent;

  clinicalCasesAccessRequestsTotalCount$: Observable<number>;
  clinicalCasesTotalInReviewCount$: Observable<number>;

  tabsConfig: Array<TabConfig> = appConfig.tabsConfig;
  tabs: Array<TabConfig>;
  user: UserProfile;
  userImage: string;
  searchFieldFullWidth = false;
  unreadUpdatesCount = 0;
  TabIds = TabIds;
  userImageSubscription: Subscription
  totalNotReadConversations = 0;
  totalOpenConnectionRequests = 0;
  totalCaseAccessRequests = 0;
  totalInReviewCount = 0;
  currentUrl: string;
  hidden = false;
  extractedPart: string;
  pendingIncomingConnectionRequestsState$: Observable<PendingConnectionRequestsSubState>;
  activeConversationsState$: Observable<ActiveConversationsStateModel>;
  totalNotReadActivities$: Observable<number>;

  private userSubscription: Subscription;
  private resizeSubscription: Subscription;
  private clinicalCasesAccessRequestsTotalCountSubscription: Subscription;
  private tabChangedSubscription: Subscription;
  private routerSubscription: Subscription;
  private tabChanged: Subject<any> = new Subject();
  private headerAndTabsVisibilitySubscription: Subscription;
  private isFromRouterNavigation = false;
  private trackTabChange = true;
  private tabChangeTrackedByManualClick: boolean;
  private pendingIncomingConnectionRequestsStateSubscription: Subscription;
  private activeConversationsStateSubscription: Subscription;
  private totalNotReadActivitiesSubscription: Subscription;
  private clinicalCasesTotalInReviewCountSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private responsiveUtils: ResponsiveUtilsService,
    private qrCodeService: QRCodeService,
    private userImageService: UserImageService,
    private connectionRequestsService: ConnectionRequestsService,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
    private appNavController: AppNavController,
    private modalController: ModalController,
    private clinicalCaseService: ClinicalCaseService,
    private globalSubjects: GlobalSubjectsService,
    private store: Store
  ) {
    this.clinicalCasesAccessRequestsTotalCount$ = this.store.select(ClinicalCasesAccessRequestsState.totalCount);
    this.pendingIncomingConnectionRequestsState$ = this.store.select(PendingConnectionRequestsState.incomingPendingConnectionRequests);
    this.activeConversationsState$ = this.store.select(ActiveConversationsState.state);
    this.totalNotReadActivities$ = this.store.select(ActivitiesState.totalNotRead);
    this.clinicalCasesTotalInReviewCount$ = this.store.select(ClinicalCasesState.totalInReviewCount);
  }

  ngOnInit(): void {

    this.userImageSubscription = this.userImageService.userImageUrl$.subscribe(imageUrl => {
      if (imageUrl !== '')
        this.userImage = imageUrl;
    });

    this.userSubscription = this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.user = userProfile;
      if (userProfile && userProfile.profileImageUrl && !this.userImage) {
        this.userImage = userProfile.profileImageUrl;
      }
    });

    this.tabs = this.tabsConfig.filter(tab => {
      return tab.type !== TabType.ADD_CONTENT;
    });

    this.resizeSubscription = this.responsiveUtils.resize.subscribe(() => {
      this.updateNavigationButtonsState();
    });

    this.totalNotReadActivitiesSubscription = this.totalNotReadActivities$.subscribe(totalNotRead => {
      this.unreadUpdatesCount = totalNotRead;
    });

    if (this.router && this.router.events && !this.routerSubscription) {
      this.routerSubscription = this.router.events.subscribe(evt => {
        if (evt instanceof NavigationStart) {
          const pathName = window.location.pathname;
          this.extractedPart = pathName.split('/').slice(2).join('/');
        } else if (evt instanceof NavigationEnd) {
          if (!this.trackTabChange) {
            this.isFromRouterNavigation = true;
          }
          this.currentUrl = evt.url;
          this.updateNavigationButtonsState();
        }
      });
    }

    this.activeConversationsStateSubscription = this.activeConversationsState$.subscribe(state => {
      this.totalNotReadConversations = state.totalNotRead;
    });

    this.pendingIncomingConnectionRequestsStateSubscription = this.pendingIncomingConnectionRequestsState$.subscribe(state => {
      this.totalOpenConnectionRequests = state?.totalCount;
    });

    this.clinicalCasesAccessRequestsTotalCountSubscription =
      this.clinicalCasesAccessRequestsTotalCount$.subscribe(count => {
        this.totalCaseAccessRequests = count;
      });

    this.clinicalCasesTotalInReviewCountSubscription = this.clinicalCasesTotalInReviewCount$?.
      subscribe((count: number) => {
        this.totalInReviewCount = count;
      });

    this.subscribeToTabChangesEvents();

    this.headerAndTabsVisibilitySubscription = this.globalSubjects.on(GlobalSubjects.HIDE_HEADER_AND_TABS).subscribe(hide => {
      this.hidden = hide as boolean;
    });
  }

  subscribeToTabChangesEvents(): void {
    this.tabChangedSubscription = this.tabChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((evt: CustomEvent) => {
      if (evt && evt.detail) {
        if (this.trackTabChange && !this.tabChangeTrackedByManualClick) {
          this.trackNavigationMenuClicked(evt.detail.value);
          this.trackTabChange = false;
        }
        this.isFromRouterNavigation = false;
        this.tabChangeTrackedByManualClick = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    this.resizeSubscription?.unsubscribe();
    this.userImageSubscription?.unsubscribe();
    this.tabChangedSubscription?.unsubscribe();
    this.headerAndTabsVisibilitySubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.clinicalCasesAccessRequestsTotalCountSubscription?.unsubscribe();
    this.pendingIncomingConnectionRequestsStateSubscription?.unsubscribe();
    this.activeConversationsStateSubscription?.unsubscribe();
    this.totalNotReadActivitiesSubscription?.unsubscribe();
    this.clinicalCasesTotalInReviewCountSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.updateNavigationButtonsState();
  }

  updateNavigationButtonsState(): void {
    if (this.buttonsSegment) {
      const currentUrl = this.router.url.split('?')?.[0];
      const currentTab = this._getTabFromUrl(currentUrl);
      if (currentTab?.route && this.buttonsSegment.value !== currentTab?.route) {
        this.buttonsSegment.value = currentTab?.route;
      }
    }
  }

  _getTabFromUrl(url: string): TabConfig {
    return this.tabs.find(tab => {
      if (tab.routeRe) {
        if (tab.routeRe.test(url)) {
          return true;
        }
      }
      return url?.endsWith(tab.route);
    });
  }

  @HostBinding('class') get viewMode(): string {
    return 'header-' + this.responsiveUtils.mode + (this.hidden ? ' hidden' : '');
  }

  get isDesktop(): boolean {
    return this.responsiveUtils.isDesktop;
  }

  onTabChange(evt: CustomEvent): void {
    if (evt.detail && evt.detail.value) {

      if (window.location.href.indexOf('(dialog:') > -1) {
        return;
      }

      if (!this.isFromRouterNavigation) {
        this.trackTabChange = true;
      }
      if (this.isDesktop) {
        this.tabChanged.next(evt);
      }
      const currentTab = this._getTabFromUrl(this.currentUrl);
      if (currentTab?.route !== evt.detail.value) {
        const path = `${this.contextService.currentContext.homePath}/${evt.detail.value}`;
        this.router.navigateByUrl(path);
      }
    }
  }

  onGoHome(): void {
    this.router.navigateByUrl(this.contextService.currentContext.homePath);
  }

  get QRCodeIsAvailable(): boolean {
    return this.qrCodeService.QRCodeIsAvailable;
  }

  navigateToQRCode(): void {
    this.trackAction('qrCode');
    this.router.navigateByUrl(this.qrCodeService.profileQRCodePageUrl);
  }

  navigateToActivity(): void {
    this.trackNavigationMenuClicked('activity');
    this.router.navigateByUrl(`${this.contextService.currentContext.homePath}/activity`);
  }

  async navigateToSearch(): Promise<void> {
    const searchDialog = await this.modalController.create({
      component: SearchDialogComponent,
      showBackdrop: true,
      componentProps: {},
      cssClass: 'search-dialog'
    });

    await searchDialog.present();
  }

  searchFieldWidthChanged(searchFieldFullSize: boolean): void {
    this.searchFieldFullWidth = searchFieldFullSize;
  }

  get showUnreadNotificationsBadge(): boolean {
    return this.unreadUpdatesCount > 0;
  }

  get showUnreadConversationsAndRequestsCountBadge(): boolean {
    return this.totalNotReadConversations > 0 || this.totalOpenConnectionRequests > 0;
  }

  private trackAction(menuItem: string): void {
    let sourceParams: TrackingSourceParams;

    if (this.extractedPart) {
      sourceParams = {
        source: this.trackingService.mapPathToActionSource(this.extractedPart)
      };
    }

    this.trackingService.trackGenericClickedAction(menuItem, 'navigationMenu', menuItem, sourceParams)
      .catch(_err => {
        console.error('Could not track navigation tab clicked/tapped action.');
      });
  }

  private trackOwnProfileImageClicked(): void {
    this.trackingService.trackGenericClickedAction('ownProfileImageClicked', 'ownProfileImageClicked', 'ownProfileImageClicked')
      .catch(_err => {
        console.error('Could not track own profile image click.');
      });
  }

  trackNavigationMenuClicked(menuItem: string): void {
    if (this.isDesktop && menuItem) {
      this.trackAction(menuItem);
    }
  }

  onOpenProfile(): void {
    this.trackOwnProfileImageClicked();
    this.appNavController.openProfile();
  }

  get showMobileSearchField(): boolean {
    return this.router?.url &&
      this.router?.url.indexOf(`${this.contextService.currentContext.key}/feed`) > -1 ?
      true : false;
  }

  minimizeSearch(): void {
    this.globalSearch.minimizeSearchField();
  }

  navigate(tab: TabConfig): void {
    this.tabChangeTrackedByManualClick = true;
    this.trackNavigationMenuClicked(tab.route);
    this.router.navigateByUrl(`${this.contextService.currentContext.key}/${tab.route}`);
  }

  async onShowSearchDialog(): Promise<void> {
    this.globalSearch.shouldFocus = true;

    const searchDialog = await this.modalController.create({
      component: SearchDialogComponent,
      componentProps: {

      },
      cssClass: 'search-dialog'
    });

    await searchDialog.present();
    this.globalSearch.focusSearchField();

  }

  isSegmentButtonChecked(tabRoute: string): boolean {
    return this.router.url.includes(`/${tabRoute}/`)
      || (tabRoute !== TabIds.ACTIVITY && tabRoute !== TabIds.FEED && this.router.url.includes(`source=${tabRoute}Page`));
  }

  get contactsTabNotificationsCount(): number {
    return (this.totalOpenConnectionRequests || 0) + (this.totalNotReadConversations || 0);
  }

  get caseFolioNotificationsCount(): number {
    return this.totalCaseAccessRequests + this.totalInReviewCount;
  }
}
