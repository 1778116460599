// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-header-container {
  position: relative;
  z-index: 10;
  background-color: var(--mi-white-color);
  box-shadow: 0 3px 20px var(--mi-shadow-color);
}

.header {
  --padding: 15px 15px;
  --max-width: 1090px;
  max-width: var(--max-width);
  margin: 0 auto;
}
.header ion-toolbar {
  --background: var(--mi-white-color);
}
.header ion-toolbar .toolbar-content {
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: auto;
  -moz-column-gap: 10px;
       column-gap: 10px;
  align-items: center;
  padding: var(--padding);
}
.header ion-toolbar .toolbar-content.mobile {
  grid-template-rows: min-content min-content;
}
.header ion-toolbar .toolbar-content.mobile .mobile-search-container {
  display: block;
}
.header ion-toolbar .toolbar-content .context-switch-container {
  grid-row: 1;
  grid-column: 1;
}
.header ion-toolbar .toolbar-content .logo-container {
  grid-row: 1;
  grid-column: 2;
  justify-self: center;
}
.header ion-toolbar .toolbar-content .logo-container app-logo {
  --image-width: 41px;
  --image-height: 12px ;
}
.header ion-toolbar .toolbar-content .search-container,
.header ion-toolbar .toolbar-content .navigation-container,
.header ion-toolbar .toolbar-content .icons-without-functionality-container {
  display: none;
}
.header ion-toolbar .toolbar-content .user-profile-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1;
  grid-column: 3;
}
.header ion-toolbar .toolbar-content .user-profile-button-container app-icon {
  color: var(--mi-black-color);
  --icon-size: 25px;
  margin-right: 10px;
}
.header ion-toolbar .toolbar-content .user-profile-button-container app-icon:hover {
  cursor: pointer;
}
.header ion-toolbar .toolbar-content .user-profile-button-container user-profile-image {
  --user-image-size: 40px;
}
.header ion-toolbar .toolbar-content .user-profile-button-container .activity.with-notification-badge {
  position: relative;
}
.header ion-toolbar .toolbar-content .user-profile-button-container .activity.with-notification-badge::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--mi-red-color);
  position: absolute;
  left: calc(100% - 10px);
  top: 0px;
}
.header ion-toolbar .toolbar-content .mobile-search-container {
  display: none;
  grid-row: 2;
  grid-column: 1/-1;
}

:host.header-desktop .header .toolbar-content {
  grid-template-columns: min-content auto min-content min-content;
}
:host.header-desktop .header .toolbar-content .context-switch-container {
  grid-row: 1;
  grid-column: 1;
}
:host.header-desktop .header .toolbar-content .logo-container {
  display: none;
}
:host.header-desktop .header .toolbar-content .search-container {
  width: 100%;
  display: inline-flex;
  grid-row: 1;
  grid-column: 2;
  justify-self: left;
  padding-left: 25px;
}
:host.header-desktop .header .toolbar-content .search-container app-global-search {
  width: 100%;
}
:host.header-desktop .header .toolbar-content .navigation-container {
  display: inline-flex;
  grid-row: 1;
  grid-column: 3;
  justify-self: center;
}
:host.header-desktop .header .toolbar-content .navigation-container ion-segment-button {
  --indicator-color: transparent !important;
}
:host.header-desktop .header .toolbar-content .navigation-container ion-segment-button .wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 5px;
}
:host.header-desktop .header .toolbar-content .navigation-container ion-segment-button .wrapper .icon-container {
  position: relative;
}
:host.header-desktop .header .toolbar-content .navigation-container ion-segment-button .wrapper .icon-container app-icon {
  position: relative;
  padding: 2.5px 0;
  display: flex;
  height: calc(100% + 5px);
}
:host.header-desktop .header .toolbar-content .navigation-container ion-segment-button app-icon {
  color: var(--mi-black-color-2);
}
:host.header-desktop .header .toolbar-content .navigation-container ion-segment-button ion-label {
  color: var(--mi-grey-color-10);
  font-size: var(--font-size-tiny);
  font-weight: var(--font-weight-regular);
}
:host.header-desktop .header .toolbar-content .navigation-container ion-segment-button.with-notification-badge app-icon {
  position: relative;
}
:host.header-desktop .header .toolbar-content .navigation-container ion-segment-button.with-notification-badge app-icon::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--mi-red-color);
  position: absolute;
  left: calc(100% - 17px);
  top: 5px;
}
:host.header-desktop .header .toolbar-content .navigation-container ion-segment-button.with-new-badge app-icon {
  position: relative;
}
:host.header-desktop .header .toolbar-content .navigation-container ion-segment-button.with-new-badge app-icon::after {
  content: "New";
  text-transform: capitalize;
  width: 30px;
  height: 10px;
  border-radius: 3px;
  background-color: var(--mi-primary-color);
  position: absolute;
  top: -5px;
  font-size: 7px;
  line-height: 12px;
  color: var(--mi-white-color);
}
:host.header-desktop .header .toolbar-content .navigation-container .segment-button-checked ion-label,
:host.header-desktop .header .toolbar-content .navigation-container .segment-button-checked app-icon {
  color: var(--mi-primary-color) !important;
}
:host.header-desktop .header .toolbar-content .navigation-container .segment-button-checked .wrapper app-icon::before {
  content: "";
  position: absolute;
  width: 47px;
  height: 100%;
  background-color: var(--mi-blue-color-4);
  border-radius: 20px;
  z-index: -1;
}
:host.header-desktop .header .toolbar-content .navigation-container .segment-button-checked ion-label {
  font-weight: var(--font-weight-medium);
}
:host.header-desktop .header .toolbar-content .navigation-container ion-label {
  text-transform: none;
}
:host.header-desktop .header .toolbar-content .icons-without-functionality-container {
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  -moz-column-gap: 30px;
       column-gap: 30px;
  display: none;
}
:host.header-desktop .header .toolbar-content .icons-without-functionality-container .icon-container app-icon {
  color: var(--mi-black-color-2);
}
:host.header-desktop .header .toolbar-content .user-profile-button-container {
  grid-row: 1;
  grid-column: 4;
  justify-self: right;
  margin-left: 20px;
}
:host.header-desktop .header .toolbar-content.search-full-width .search-container app-global-search {
  width: 100%;
}
:host.header-desktop .header .toolbar-content.search-full-width .navigation-container {
  display: none;
}
:host.header-desktop .header .toolbar-content.search-full-width .icons-without-functionality-container {
  grid-row: 1;
  grid-column: 3;
  display: grid;
}
:host.header-desktop .header .toolbar-content.search-full-width .user-profile-button-container {
  grid-row: 1;
  grid-column: 4;
}

:host.header-mobile.hidden {
  display: none;
}

.badge {
  position: absolute;
  top: -1px;
  left: calc(100% - 19px);
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--mi-red-color);
  border-radius: 50%;
}
.badge.contacts {
  left: calc(100% - 14px);
}
.badge span {
  font-size: var(--font-size-tiny);
  font-weight: bold;
  color: var(--mi-white-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
