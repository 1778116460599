import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { NavControllerService } from 'src/app/services/nav-controller.service';
import {ResponsiveUtilsService} from '../../services/utils/responsive-utils.service';
import {NetworkService} from '../../services/network.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy {

  @Input() customBackButtonIcon: string;
  @Input() title: string;
  @Input() showActions: boolean;
  @Input() showCustomContainer: boolean;
  @Input() backIsCustomAction: boolean;
  @Input() noBorder: boolean;
  @Input() forceBackArrowIcon = false;

  @Output() back: EventEmitter<any> = new EventEmitter();

  networkStatus: string;
  private networkStatusSubscription: Subscription;

  constructor(
    private responsiveUtilsService: ResponsiveUtilsService,
    private navContoller: NavControllerService,
    private networkService: NetworkService
  ) { }

  ngOnInit(): void {
    this.networkStatusSubscription = this.networkService.getNetworkStatus().subscribe((networkStatus) => {
      this.networkStatus = networkStatus;
    })
  }

  ngOnDestroy(): void {
    this.networkStatusSubscription?.unsubscribe();
  }

  onBack(): void {
    if(this.backIsCustomAction){
      return this.back.emit();
    }
    this.navContoller.back();
  }

  get shouldShowActionsContainer(): boolean {
    return this.showActions && !this.showCustomContainer;
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get showNoInternetConnection(): boolean {
    return !this.isDesktop && this.networkStatus !== 'online';
  }

}
