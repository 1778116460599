// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-with-badge {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.image-with-badge .text-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--mi-white-color);
}
.image-with-badge .text-container span {
  font-size: 11px;
  font-weight: var(--font-weight-semi-bold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image-with-badge .video-style {
  background-color: var(--mi-thumbnails-video-color);
}
.image-with-badge .event-style {
  background-color: var(--mi-thumbnails-event-color);
}
.image-with-badge .article-style {
  background-color: var(--mi-thumbnails-article-color);
}
.image-with-badge .group-style {
  background-color: var(--mi-thumbnails-group-color);
}
.image-with-badge .post-style {
  background-color: var(--mi-thumbnails-group-color);
}
.image-with-badge .default-style {
  background-color: var(--mi-thumbnails-event-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
