import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrowserTab } from '@awesome-cordova-plugins/browser-tab/ngx';

import { AOSignInResult, SignInParams } from './ao-auth.model';
import { AOAuthServiceInterface } from './ao-auth.service.interface';

import { MIAOUtilsService } from './mi-ao-utils.service.service';
import { UrlUtilsService } from '../../utils/url-utils.service';
import { SchemaValidatorService } from '../../schema-validator.service';
import { LocationService } from '../../location.service';
import { LOCAL_STORAGE_SERVICE, SESSION_STORAGE_SERVICE, StorageService } from '../../storage/storage.service';

import { AppTranslationService } from '../../app-translation.service';
import { AOAuthDeviceBase } from './ao-auth.device.base';
import { RedirectResult } from '../logic/auth-logic.service.interface';

@Injectable()
export class AOAuthDeviceAndroidService extends AOAuthDeviceBase implements AOAuthServiceInterface {

  constructor(
    protected miaoUtilsService: MIAOUtilsService,
    protected urlUtilsService: UrlUtilsService,
    @Inject(SESSION_STORAGE_SERVICE) protected sessionStorageService: StorageService,
    protected schemaValidator: SchemaValidatorService,
    @Inject(LOCAL_STORAGE_SERVICE) protected storage: StorageService,
    protected httpClient: HttpClient,
    protected locationService: LocationService,
    protected appTranslationService: AppTranslationService,
    private browserTab: BrowserTab
  ) {
    super();
  }

  signUp(): Promise<RedirectResult> {

    const signUpUrl = this._getSignUpUrl(this.getSignupRedirectUrl());

    return new Promise((resolve, reject) => {
      this.browserTab.isAvailable().then(available => {
        if (available) {
          this.browserTab.openUrl(signUpUrl).then(() => {
            console.log('chrome tab is opened')
          }).catch(err => {
            console.error(err);
            reject(err);
          });
        } else {
          console.error('BrowserTab not available');
          resolve(null);
        }
      })
    });
  }

  _signIn(signInParams: SignInParams, storage: StorageService): Promise<AOSignInResult> {
    return this._saveSignInParams(signInParams, storage)
      .then(params => {
        return new Promise((resolve, reject) => {
          this.browserTab.isAvailable().then(available => {
            if (available) {
              this.browserTab.openUrl(params.authenticationUrl).then(() => {
                console.log('chrome tab is opened')
              }).catch(err => {
                console.error(err);
                reject(err);
              });
            } else {
              console.error('BrowserTab not available');
              resolve(null);
            }
          })
        });
      });
  }

  _signOut(): Promise<any> {

    const logoutUrl = this._getSignOutUrl(this.getLogoutRedirectUrl());

    if (!logoutUrl) {
      return Promise.reject('no logout url');
    }

    return new Promise((resolve, reject) => {
      return this.browserTab.isAvailable().then(available => {
        if (available) {
          this.browserTab.openUrl(logoutUrl).then(() => {
            console.log('chrome tab is opened')
          }).catch(err => {
            console.error(err);
            reject(err);
          });
        } else {
          console.error('BrowserTab not available');
          resolve(null);
        }
      })
    });
  }

  signOut(): Promise<any> {
    return Promise.all([
      this.miaoUtilsService.signOut(),
      this.storage.remove(this.TOKENS_STORAGE_KEY)
    ]).then(() => {
      setTimeout(() => {
        this._signOut();
      }, 500);
    });
  }

  getRedirectUrl(): string {
    return this.config.authenticationAO.redirectUri;
  }

  getSignupRedirectUrl(): string {
    return this.config.authenticationAO.signUpRedirectUri;
  }

  getLogoutRedirectUrl(): string {
    return this.config.authenticationAO.logoutRedirectUri;
  }

  openAoLink(url: string): Promise<void> {
    if (url && url.length > 0) {
      return new Promise((resolve, reject) => {
        this.browserTab.isAvailable().then(available => {
          if (available) {
            this.browserTab.openUrl(url).then(() => {
              console.log('chrome tab is opened')
            }).catch(err => {
              console.error(err);
              reject(err);
            });
          } else {
            console.error('BrowserTab not available');
            resolve(null);
          }
        })
      });
    }

    return Promise.reject();
  }

}

