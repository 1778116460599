// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --add-content-card-padding-top: 15px;
  --add-content-card-padding-bottom: 15px;
  --add-content-card-padding-start: 15px;
  --add-content-card-padding-end: 15px;
  --add-content-card-background-color: var(--mi-white-color);
  --border-color: #888888;
  --action-buttons-height: 62px;
}

.add-content-card {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content min-content;
  grid-template-columns: 100%;
  align-items: center;
  padding: var(--add-content-card-padding-top) 0 var(--add-content-card-padding-bottom) 0;
  border-radius: 8px;
  background-color: var(--add-content-card-background-color);
}
.add-content-card .add-post-container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: min-content auto;
  -moz-column-gap: 13px;
       column-gap: 13px;
  align-items: center;
  padding: 0 var(--add-content-card-padding-start);
}
.add-content-card .add-post-container .user-profile-image-container {
  grid-row: 1;
  grid-column: 1;
}
.add-content-card .add-post-container .add-post-button-container {
  width: 100%;
  height: 100%;
  grid-row: 1;
  grid-column: 2;
  border: solid 1px #2D79F3;
  border-radius: 8px;
}
.add-content-card .add-post-container .add-post-button-container .add-post {
  width: 100%;
  height: 40px;
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-large);
  color: var(--mi-grey-color-13);
  border-radius: 8px;
  text-align: left;
  background-color: var(--mi-blue-color-5);
  padding: 0 14px;
  outline: none;
}

.button-content {
  display: flex;
  justify-content: space-between;
  color: var(--mi-black-color);
}
.button-content .icon {
  color: #2D79F3;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
