import { Component } from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {IconComponent} from '../../modules/icon/icon.component';
import {ResponsiveUtilsService} from '../../services/utils/responsive-utils.service';

@Component({
  selector: 'app-no-internet-connection-banner',
  templateUrl: './no-internet-connection-banner.component.html',
  styleUrls: ['./no-internet-connection-banner.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    IconComponent,
  ]
})
export class NoInternetConnectionBannerComponent {

  constructor(private responsiveUtilsService: ResponsiveUtilsService) { }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

}
