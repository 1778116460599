import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';

// services
import { GroupsService } from '../../services/groups/groups.service';
import {
  Group,
  GroupByIdSuccessResponse,
  GroupMembersResponse
} from '../../services/yeti-protocol/chatter-api';
import { GroupMember } from '../../services/groups/group.model';
import { AuthService } from '../../services/auth/auth.service';
import { UserProfile } from '../../services/yeti-protocol/auth/mi';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { InfoSheetService } from '../../modules/info-sheet/services/info-sheet.service';
import { SeeAllParticipantsComponent } from '../see-all-participants/see-all-participants.component';
import { ResponsiveUtilsService } from '../../services/utils/responsive-utils.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { ToastMode, ToastService } from '../../services/toast.service';
import appConfig from 'src/config/config';
import { SeeAllGroupModeratorsComponent } from '../see-all-group-moderators/see-all-group-moderators.component';
import { InfoSheetActionItem } from 'src/app/modules/info-sheet/models/info-sheet-action-item.model';
import { ActionSource } from 'src/app/services/yeti-protocol/tracking';
import { ContextDialogsUI, CONTEXT_DIALOGS_UI, ConfirmDialogData } from 'src/app/services/dialogs/dialogs.ui.interface';
import { DialogsUIService } from 'src/app/services/dialogs/dialogs.ui.service';

interface GroupParticipantsComponentConfig {
  groupModeratorsMaxNum: number;
  maxNumberVerticalItems: number;
}

@Component({
  selector: 'app-group-participants',
  templateUrl: './group-participants.component.html',
  styleUrls: ['./group-participants.component.scss'],
})
export class GroupParticipantsComponent implements OnInit {

  @Input() groupId: string;
  @ViewChild('mySearchbar') searchbar: IonSearchbar;
  selectedMemberInOptions: GroupMember;
  group: Group;
  groupMembers: Array<GroupMember> = [];
  disabledInviteButton: boolean;
  user: UserProfile;
  groupModerators: Array<GroupMember> = [];
  groupParticipantsSearch: Array<GroupMember> = [];
  hideNonParticipants = false;
  searchParticipantTerm = '';
  infoSheetId = 'group-participants-info-card';
  config: GroupParticipantsComponentConfig = appConfig;

  constructor(
    private authService: AuthService,
    private groupsService: GroupsService,
    private modalController: ModalController,
    private dialogs: DialogsUIService,
    @Inject(CONTEXT_DIALOGS_UI) private contextDialogs: ContextDialogsUI,
    private infoSheetService: InfoSheetService,
    private responsiveUtilsService: ResponsiveUtilsService,
    private appTranslationService: AppTranslationService,
    private toast: ToastService
  ) {
  }

  ngOnInit(): void {
    this.authService.userProfileAsObservable.subscribe(userProfile => {
      this.user = userProfile;
    });
    this.loadGroupData(this.groupId);
    this.getGroupMembers(this.groupId).then(response => {
      this.groupMembers = response;
    });
    this.infoSheetId = this.infoSheetService.generateNewInfoSheetIdIfDuplicate(this.infoSheetId);
  }

  get searchButtonVisibility(): boolean {
    return (this.searchParticipantTerm && this.searchParticipantTerm.length >= 2);
  }

  get title(): string {
    if (this.hideNonParticipants) {
      return `${this.group.totalMembersCount} participants`;
    }
    return this.appTranslationService.instant('app.groups.GroupParticipants.participants');
  }

  get isGroupOwner(): boolean {
    if (this.group && this.user) {
      return this.group?.owner?.userId === this.user.id;
    }
    return false;
  }

  get isGroupModerator(): boolean {
    if (this.user && this.groupModerators && this.groupModerators.length) {
      return this.groupModerators.some(moderator => moderator.userId === this.user.id);
    }
    return false;
  }

  loadGroupData(groupId: string): void {
    this.groupsService.getGroupById(groupId).then(response => {
      if (response) {
        this.group = (response as GroupByIdSuccessResponse).result;
        this.groupModerators = this.group.moderators;
        // Needed for correct displaying of options pop-up
        this.groupModerators.forEach(moderator => {
          moderator.role = 'moderator';
        });
      }
    })
  }

  getGroupMembers(groupId: string): Promise<Array<GroupMember>> {

    return this.groupsService.getGroupMembers(groupId, 0, 10)
      .then((groupMembersResponse: GroupMembersResponse) => {
        if (groupMembersResponse) {
          return groupMembersResponse.result;
        }
        return [];
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  _getParticipants(term: string): Promise<Array<GroupMember>> {
    return this.groupsService.searchForGroupParticipant(this.groupId, term)
      .then(response => {
        if (response) {
          return response.result;
        }
        return [];
      });
  }

  onSearchParticipants(): void {
    if (this.searchParticipantTerm && this.searchParticipantTerm.length >= 2) {
      this._getParticipants(this.searchParticipantTerm)
        .then(participants => {
          this.groupParticipantsSearch = participants;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  onShowOnlyParticipants(): void {
    this.hideNonParticipants = true;
  }

  get isShowInviteParticipantsButton(): boolean {
    if (this.isShowForOwnerOrModerators) {
      return true;
    }
    return this.group.visibility === 'Public';
  }

  get isShowForOwnerOrModerators(): boolean {
    return !!(this.isGroupOwner || this.isGroupModerator);
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  async presentActionSheet(member: GroupMember, event: Event): Promise<void> {
    this.selectedMemberInOptions = member;
    if (this.isDesktop) {
      this.infoSheetService.openWeb(this.infoSheetActions, event)
        .then(action => {
          return this._processWebMenuResult(action, member);
        });
    } else {
      this.infoSheetService.open(this.infoSheetId);
    }
  }

  async _processWebMenuResult(action: InfoSheetActionItem, member: GroupMember): Promise<void> {
    if (action && action.code) {
      const newRole = this._actionCodeToRole(action.code, member.role);
      if (newRole === 'moderator') {
        this.onAssignModerator();
      } else {
        if (newRole === 'removed') {
          // member or moderator was removed. Leave role as the existing one and set status to removed

          const shouldProceed = await this.showRemoveUserConfirmDialog();

          if (!shouldProceed) {
            return;
          }

          this.updateGroupMember(this.groupId, member, member.role, 'removed');
        } else {
          this.updateGroupMember(this.groupId, member, newRole, null);
        }
      }
    }
  }

  _actionCodeToRole(actionCode: string, defaultRole: string): string {
    const mapping = {
      ASSIGN_MODERATOR: 'moderator',
      REMOVE_MODERATOR: 'member',
      REMOVE_FROM_GROUP: 'removed'
    };
    return mapping[actionCode] || defaultRole;
  }

  updateGroupMember(groupId: string, member: GroupMember, role: string, status?: string): void {
    this.groupsService.updateGroupMember(member.userId, role, groupId, status,).then(response => {
      if (response) {
        member.role = role;
        this.updateGroupMembers();

        if (status === 'removed') {
          this.toast.showWithMessage(
            this.appTranslationService.instant('app.groups.GroupParticipants.remove-user-from-group-toast-message')
          );
        }
      }
    })
      .catch(() => {
        this.dialogs.showErrorDialog('Something went wrong, try again!');
      })
  }

  onClose(): void {
    if (this.hideNonParticipants) {
      this.hideNonParticipants = false;
      this.searchbar.value = null;
      this.groupParticipantsSearch = [];
    } else {
      this.modalController.dismiss();
    }
  }

  updateGroupMembers(): void {
    this.loadGroupData(this.groupId);
    this.getGroupMembers(this.groupId).then(response => {
      this.groupMembers = response;
    });
  }

  async onInviteParticipant(): Promise<void> {
    const modalController = this.modalController;
    const executeServiceMethod = (fn): void => {
      fn(modalController);
    };
    return this.contextDialogs.inviteGroupParticipants((fn) => { executeServiceMethod(fn); },
      this.groupId, this.group.visibility, this.user);
  }

  onProfileImageClicked(userId: string): void {
    const source = ActionSource.groupParticipants;
    this.modalController.dismiss({
      actionKey: 'open-user-profile',
      userId,
      source
    }, 'cancel');
  }

  async onGoToAllParticipants(): Promise<void> {
    const participants = await this.modalController.create({
      component: SeeAllParticipantsComponent,
      cssClass: 'all-participants-dialog',
      componentProps: {
        groupId: this.groupId,
        showActions: this.isShowForOwnerOrModerators,
        disableProfileOpen: true
      }
    });
    participants.present();
    participants.onDidDismiss().then(res => {
      this.onCloseAllUsersList(res);
    });
  }

  async onOpenAllModerators(): Promise<void> {
    const moderators = await this.modalController.create({
      component: SeeAllGroupModeratorsComponent,
      cssClass: 'all-moderators-dialog',
      componentProps: {
        groupId: this.groupId,
        groupOwnerOrModerator: this.isShowForOwnerOrModerators
      }
    });
    moderators.present();
    moderators.onDidDismiss().then(res => {
      this.onCloseAllUsersList(res);
    });
  }

  /* eslint-disable */
  onCloseAllUsersList(res: any): void {
    /* eslint-enable */
    if (res && res?.data?.actionKey === 'open-user-profile') {
      setTimeout(() => {
        this.modalController.dismiss({
          actionKey: 'open-user-profile',
          userId: res?.data.userId
        }, 'cancel');
      }, 10);
    } else {
      this.updateGroupMembers();
    }
  }

  onAssignModerator(): void {
    if (this.group.moderators && this.group.moderators.length === this.config.groupModeratorsMaxNum) {
      this.toast.show('app.groups.GroupParticipants.maximum-moderators', 'app.common.error-default', ToastMode.ERROR);
    } else {
      this.updateGroupMember(this.groupId, this.selectedMemberInOptions, 'moderator')
    }
  }

  get infoSheetActions(): Array<InfoSheetActionItem> {
    const actions: Array<InfoSheetActionItem> = [];
    if (this.selectedMemberInOptions) {
      if (this.selectedMemberInOptions.role !== 'moderator') {
        actions.push({
          id: 'assign-moderator',
          icon: 'md-icon-crown-plus',
          textKey: 'app.groups.GroupParticipants.assign-moderator',
          code: 'ASSIGN_MODERATOR',
          handler: () => {
            this.onAssignModerator();
          }
        });
      }
      if (this.selectedMemberInOptions.role === 'moderator') {
        actions.push({
          id: 'unassign-moderator',
          icon: 'md-icon-crown-minus',
          textKey: 'app.groups.GroupParticipants.remove-moderator',
          code: 'REMOVE_MODERATOR',
          handler: () => {
            this.updateGroupMember(this.groupId, this.selectedMemberInOptions, 'member');
          }
        });
      }
      actions.push({
        id: 'remove-user',
        icon: 'md-icon-user-minus',
        textKey: 'app.groups.GroupParticipants.remove-from-group',
        code: 'REMOVE_FROM_GROUP',
        handler: () => {
          this.removeUserFromGroup();
        }
      });
    }
    return actions;
  }

  get groupOwnerOrModerator(): boolean {
    return this.group?.status === 'moderator' || this.group?.status === 'owner';
  }

  async removeUserFromGroup(): Promise<void> {

    const shouldProceed = await this.showRemoveUserConfirmDialog();

    if (!shouldProceed) {
      return;
    }

    this.updateGroupMember(this.groupId, this.selectedMemberInOptions, this.selectedMemberInOptions.role, 'removed');
  }

  async showRemoveUserConfirmDialog(): Promise<boolean> {
    const confirmActionKey = 'confirm';
    const cancelActionKey = 'cancel';

    const confirmDialogData: ConfirmDialogData = {
      componentProps: {
        title: {
          translationKey: this.appTranslationService.instant('app.groups.GroupParticipants.remove-user-from-group-confirmation.title')
        },
        message: {
          translationKey: this.appTranslationService.instant('app.groups.GroupParticipants.remove-user-from-group-confirmation.message')
        },
        actions: [
          {
            key: cancelActionKey,
            label: {
              translationKey:
                this.appTranslationService.instant('app.groups.GroupParticipants.remove-user-from-group-confirmation.cancel-btn')
            },
            className: 'secondary'
          },
          {
            key: confirmActionKey,
            label: {
              translationKey:
                this.appTranslationService.instant('app.groups.GroupParticipants.remove-user-from-group-confirmation.confirm-btn')
            },
            className: 'primary'
          }
        ]
      }
    }

    const confirmModal = await this.dialogs.createConfirmDialog(confirmDialogData);
    confirmModal.present();

    try {
      const res = await confirmModal.onDidDismiss();

      if (!res?.data?.actionKey) {
        return false;
      }

      if (res?.data?.actionKey === confirmActionKey) {
        return true;
      } else {
        return false;
      }

    } catch (err) {
      console.error(err);
      return false;
    }
  }
}
