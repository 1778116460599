import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import { RichTextDocumentEditorComponent } from
  'src/app/modules/rich-text-document-editor/components/rich-text-document-editor/rich-text-document-editor.component';
import { stripHtmlTagsFromString } from 'src/app/services/utils/string-utils';
import {ResponsiveUtilsService} from '../../services/utils/responsive-utils.service';

@Component({
  selector: 'app-popup-dialog',
  templateUrl: './edit-text-dialog.component.html',
  styleUrls: ['./edit-text-dialog.component.scss'],
})
export class EditTextDialogComponent implements OnInit, AfterViewInit {
  @Input() headerResourceId: string;
  @Input() text = '';
  @Input() textLimit = 0;
  @Input() preventSaveWhenEmpty: boolean;
  @Input() simpleTextArea: boolean;
  @Input() showAiTag: boolean;

  @ViewChild('textField') textField: { setFocus: () => void };
  @ViewChild('richTextDocumentEditor') richTextDocumentEditor: RichTextDocumentEditorComponent;

  charsLeftCount = 0;

  constructor(
    private modalController: ModalController,
    private responsiveUtilsService: ResponsiveUtilsService,
    private platform: Platform
  ) { }

  ngOnInit(): void {
    if (this.textLimit > 0) {
      this.charsLeftCount = this.textLimit - this.textLength;
    }
  }

  ngAfterViewInit(): void {
    if (!this.simpleTextArea) {
      setTimeout(() => {
        this.setRichTextEditorHeightValues();
      }, 0);
    }
  }

  onClose(): void {
    this.modalController.dismiss(null, 'close');
  }

  onAction(actionKey: string): void {
    this.modalController.dismiss(actionKey);
  }

  onChange(text: string): void {

    this.text = text;

    if (this.hasTextLimit) {
      this.charsLeftCount = this.textLimit - this.textLength;
      if (this.charsLeftCount < 0) {
        this.charsLeftCount = - this.charsLeftCount;
      }
    }
  }

  onSave(): void {
    this.modalController.dismiss(this.text || '', 'save');
  }

  get hasTextLimit(): boolean {
    return this.textLimit > 0;
  }

  get isTextLengthOK(): boolean {
    if (this.hasTextLimit) {
      return this.textLength <= this.textLimit;
    }
    return true;
  }

  get textLength(): number {

    if (!this.text) {
      return 0;
    }

    if (this.simpleTextArea) {
      return this.text?.length || 0;
    }

    return stripHtmlTagsFromString(this.text || '')?.length || 0;
  }

  get canSave(): boolean {

    if (this.preventSaveWhenEmpty && !this.text?.length) {
      return false;
    }

    if (this.hasTextLimit) {
      return this.isTextLengthOK;
    }
    return this.textLength > 0;
  }

  setRichTextEditorHeightValues(): void {
    if (!this.simpleTextArea && this.richTextDocumentEditor && this.richTextDocumentEditor.setEditorHeight) {
      this.richTextDocumentEditor?.setEditorHeight('251px', '251px');
    }
  }

  get isDesktop(): boolean {
    return this.responsiveUtilsService.isDesktop;
  }

  get isAndroid(): boolean {
    return this.platform.is('android');
  }
}
