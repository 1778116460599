import { Inject, Injectable } from '@angular/core';
import { OrcIdAuthDeviceServiceInterface } from './orcid-auth-device.service.interface';
import appConfig from 'src/config/config';
import { InAppBrowserColors, Platform } from 'src/config/config.model';
import { AppTranslationService } from '../../app-translation.service';
import { TRACKING_SERVICE, TrackingService } from '../../tracking/tracking.model';
import { ActionTracked, LinkOrcIdUrlOpenedTrackingParam, TrackingRequest } from '../../yeti-protocol/tracking';
import {SafariViewController} from '@awesome-cordova-plugins/safari-view-controller/ngx';

export interface OrcIdAuthDeviceIOSServiceConfig {
  inAppBrowserColors: InAppBrowserColors;
}

@Injectable()
export class OrcIdAuthDeviceIOSService implements OrcIdAuthDeviceServiceInterface {

  config: OrcIdAuthDeviceIOSServiceConfig = appConfig;

  constructor(
    protected safariViewController: SafariViewController,
    protected appTranslationService: AppTranslationService,
    @Inject(TRACKING_SERVICE) protected trackingService: TrackingService
  ) { }

  signIn(orcIdOAuthUrl: string): Promise<any> {
    this.safariViewController.show({
      url: orcIdOAuthUrl,
      hidden: false,
      animated: false,
      transition: 'curl',
      enterReaderModeIfAvailable: false,
      barColor: appConfig.inAppBrowserColors.toolbarcolor
    })
      .subscribe({
        next: (_result) => {/* the result is never used */ },
        error: (error) => {
          this._trackErrorOpeningOrcIdLink(error);
          console.error(error);
        }
      });

    return Promise.resolve();
  }

  _trackErrorOpeningOrcIdLink(error: Error): void {
    const paramsToTrack: LinkOrcIdUrlOpenedTrackingParam = {
      objectId: 'linkOrcIdUrlOpened',
      objectType: 'linkOrcIdUrlOpened',
      objectTitle: 'Error',
      error: JSON.stringify(error),
      platform: Platform.IOS
    };
    const trackData: TrackingRequest = {
      action: ActionTracked.linkOrcIdUrlOpened,
      params: paramsToTrack
    };

    this.trackingService.track(trackData);
  }
}

