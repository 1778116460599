import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// models
import { PeopleSearch } from 'src/app/services/yeti-protocol/search';

// services
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import {ActionSource} from '../../services/yeti-protocol/tracking';

@Component({
  selector: 'app-user-profile-info-card',
  templateUrl: './user-profile-info-card.component.html',
  styleUrls: ['./user-profile-info-card.component.scss'],
})
export class UserProfileInfoCardComponent implements OnInit {
  @Input() userProfile: PeopleSearch;
  @Input() isCustomOpenProfile = false;
  @Input() source: ActionSource;
  @Output() openProfile: EventEmitter<void> = new EventEmitter();

  imageUrl: string;

  constructor(
    private appNavController: AppNavController
  ) { }

  ngOnInit(): void {
    if(this.userProfile && this.userProfile?.userId) {
      this.imageUrl = this.userProfile.profileImageUrl;
    }
  }

  onOpenProfile(): void {
    if (this.isCustomOpenProfile){
      return this.openProfile.emit();
    }
    this.appNavController.openPublicUserProfile(this.userProfile?.userId, this.source);
  }

}
