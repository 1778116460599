import { InjectionToken } from '@angular/core';
import { ActionSource, ImpressionTrackingRequest, TrackingRequest } from '../yeti-protocol/tracking';

export const UUID_KEY = 'uuid-key';

export interface TrackingSourceParams {
  source?: string;
  campaign?: string;
  // MYAO-5847: Pardot-triggered AO campaigns support
  utm_source?: string;
  utm_campaign?: string;
}

export interface TrackingService {

  track(data: TrackingRequest): Promise<void>;

  /**
   * Use when we need to track generic action which will be indexed with verb: clicked
   * @param objectId
   * @param objectType
   * @param objectTitle
   */
  trackGenericClickedAction(objectId: string, objectType: string, objectTitle?: string, sourceParams?: TrackingSourceParams): Promise<void>;

  /**
   * Use when we need to track translated action
   * @param objectId - the myAO id of the object translated
   * @param objectType - type of content translated (article, video, post, case, ...)
   * @param objectTitle - title
   */
  trackTranslateAction(objectId: string, objectType: string, objectTitle: string,
                       toLanguage: string, action: string, awsPersonalisationId?: string,
                       sourceParams?: TrackingSourceParams): Promise<void>;

  /**
   * Use when we need to track generic pages loaded which will be indexed with elk verb: viewed
   * Generic pages to be loaded: Feed, Casefolio, Groups, Contacts, Activity, Events
   * @param objectId: feed | casefolio | groups | contacts | activity | events
   * @param objectTitle: title of generic page opened
   * @param scope: why the page is opened usually used for tracking maintenance page.
   * @param reference: url because the page is opened usually used for tracking maintenance page.
   */
  trackGenericPageOpenedAction(objectId: string, objectTitle?: string, scope?: string, reference?: string): Promise<void>;

  trackGenericModalOpenedAction(objectId: string, objectTitle?: string): Promise<void>;

  trackListItemImpression(data: ImpressionTrackingRequest): Promise<void>;

  mapPathToActionSource(path: string): ActionSource;
}

export const TRACKING_SERVICE = new InjectionToken<TrackingService>('tracking.service');
