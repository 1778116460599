import { Inject, Injectable, Optional } from '@angular/core';
import { BrowserTab } from '@awesome-cordova-plugins/browser-tab/ngx';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { CookieService } from 'ngx-cookie-service';

import { Observable } from 'rxjs';

import { Platform } from 'src/config/config.model';
import { AuthProvider } from 'src/app/services/yeti-protocol/auth/provider';
import {
  UserProfile,
  UpdateUserProfileData,
  SocialProfile,
  DeleteProfileResponse,
} from 'src/app/services/yeti-protocol/auth/mi';

import {
  AuthLogicServiceInterface,
  AuthRequestOptions,
  ProcessRedirectResult,
  RedirectResult,
  SignInData,
  SignInNextStep,
  SignInResult,
  ProcessRedirectResultSocialSignUp,
  SignUpStatus,
  ProcessRedirectResultRedirect,
} from '../auth-logic.service.interface';
import { MIAuthService, PREVENT_PROFILE_RELOAD_BELLOW_X_MS_DIFF, SocialSignInData } from '../../mi/mi-auth.service';
import {
  AOAuthServiceInterface,
  AO_AUTH_SERVICE,
} from '../../ao/ao-auth.service.interface';
import { AOAuthBrowserService } from '../../ao/ao-auth.browser.service';
import { MIAOUtilsService } from '../../ao/mi-ao-utils.service.service';
import { LocationService } from 'src/app/services/location.service';

import { AORightsData, AOSocialSignInResult } from '../../ao/ao-auth.model';
import { ActionSource } from '../../../yeti-protocol/tracking';
import { AOAuthDeviceIOSService } from '../../ao/ao-auth.device-ios.service';
import { AOAuthDeviceAndroidService } from '../../ao/ao-auth.device-android.service';

import appConfig from 'src/config/config';

const REGISTER_SOCIAL_ACCOUNT = 'register-social-account';

@Injectable()
export class AuthLogicService implements AuthLogicServiceInterface {
  appConfig = appConfig; // for testing

  constructor(
    private miAuthService: MIAuthService,
    @Inject(AO_AUTH_SERVICE) private aoAuthService: AOAuthServiceInterface,
    protected miaoUtilsService: MIAOUtilsService,
    private locationService: LocationService,
    @Optional() private cookieService: CookieService // @Inject(AUTH_UI_SERVICE) private authUIService: AuthUIServiceInterface,
  ) { }

  static get requiredAuthServices(): any[] {
    const services: any[] = [MIAuthService, MIAOUtilsService];
    switch (appConfig.platform) {
      case Platform.IOS:
        services.push(SafariViewController);
        services.push({
          provide: AO_AUTH_SERVICE,
          useClass: AOAuthDeviceIOSService,
        });
        break;
      case Platform.ANDROID:
        services.push(BrowserTab);
        services.push({
          provide: AO_AUTH_SERVICE,
          useClass: AOAuthDeviceAndroidService,
        });
        break;
      default:
        services.push({
          provide: AO_AUTH_SERVICE,
          useClass: AOAuthBrowserService,
        });
        services.push(CookieService);
    }
    return services;
  }

  isSignedIn(): Promise<boolean> {
    return this.miAuthService.isSignedIn();
  }

  get isSignedInAsObservable(): Observable<boolean> {
    return this.miAuthService.isSignedInAsObservable;
  }

  get userProfileAsObservable(): Observable<UserProfile> {
    return this.miAuthService.userProfileAsObservable;
  }

  get userAdminGroupsAsObservable(): Observable<string[]> {
    return this.miAuthService.userAdminGroupsAsObservable;
  }

  get accessBlockedAsObservable(): Observable<boolean> {
    return this.miAuthService.accessBlockedAsObservable;
  }

  signIn(provider: AuthProvider, data?: SignInData): Promise<SignInResult> {
    switch (provider) {
      case AuthProvider.MI:
        return this._signInForMI(data);
      case AuthProvider.AO:
        return this._signInForAO(undefined);
      default:
        return Promise.reject('provider is not supported: ' + provider);
    }
  }

  signOut(): Promise<RedirectResult> {
    const promises = [
      this.miAuthService.signOut(),
      this.aoAuthService.signOut(),
    ];
    return Promise.all(promises).then(() => {
      setTimeout(() => {
        this.miAuthService._onProfileChanged(null);
      }, 500);
      return null;
    });
  }

  getSignupStatus(context: string): Promise<SignUpStatus> {
    return this.miAuthService.getSignupStatus(context);
  }

  getProfile(
    context: string,
    withoutCaching: boolean = false
  ): Promise<UserProfile> {
    if (withoutCaching) {
      return this.miAuthService.getProfile(context, true, PREVENT_PROFILE_RELOAD_BELLOW_X_MS_DIFF).then(profile => {
        return profile;
      });
    }
    return this.miAuthService.getProfile(context);
  }

  updateProfile(
    context: string,
    data: UpdateUserProfileData,
    withoutCaching = false,
    source: ActionSource = ActionSource.unspecified
  ): Promise<UserProfile> {
    return this.miAuthService.updateProfile(
      context,
      data,
      withoutCaching,
      source
    );
  }

  deleteProfile(context: string): Promise<DeleteProfileResponse> {
    return this.miAuthService.deleteProfile(context);
  }

  reloadProfile(context: string): Promise<UserProfile> {
    return this.miAuthService.getProfile(context, true, PREVENT_PROFILE_RELOAD_BELLOW_X_MS_DIFF);
  }

  resetPassword(email: string): Promise<boolean> {
    return this.miAuthService.resetPassword(email);
  }

  securePost<D, T>(
    url: string,
    postData: D,
    options?: AuthRequestOptions
  ): Observable<T> {
    return this.miAuthService.securePost(url, postData, options);
  }

  secureGet<T>(url: string, options?: AuthRequestOptions): Observable<T> {
    return this.miAuthService.secureGet<T>(url, options);
  }

  secureDelete<D, T>(
    url: string,
    deleteData: D,
    options?: AuthRequestOptions
  ): Observable<T> {
    return this.miAuthService.secureDelete(url, deleteData, options);
  }

  securePut<D, T>(
    url: string,
    putData: D,
    options?: AuthRequestOptions
  ): Observable<T> {
    return this.miAuthService.securePut(url, putData, options);
  }

  fakeSignInExpire(): Promise<void> {
    return this.miAuthService.fakeSignInExpire();
  }

  _extractNameFromSocialProfile(
    profileData: SocialProfile
  ): Record<string, string> {
    const nameData: Record<string, string> = {};
    const name = profileData.original.name;
    if (name) {
      if (name.givenName) {
        nameData.firstName = name.givenName;
      }
      if (name.familyName) {
        nameData.lastName = name.familyName;
      }
    }
    return nameData;
  }

  _reloadUserRightsIfRequires(data: {
    rights: AORightsData;
    aoProfile: any;
  }): Promise<void> {
    if (!data.rights && data.aoProfile) {
      return this.miaoUtilsService
        .getUserRights(data.aoProfile, true)
        .then(() => {
          /* ignore data */
        });
    }
    return Promise.resolve();
  }

  _processAOSignIn(
    signInResult: AOSocialSignInResult
  ): Promise<ProcessRedirectResult> {
    const data: SocialSignInData = {
      profile: signInResult.profile,
      accessToken: signInResult.accessToken,
    };
    if (signInResult.aoEmail) {
      data.providerData = {
        aoEmail: signInResult.aoEmail,
        userPrincipalName: signInResult.userPrincipalName,
      };
      const aoData = signInResult?.rights?.aoData;
      if (aoData) {
        data.providerData.aoData = aoData;
      }
    }

    return this.miAuthService
      .signInSocial(signInResult.profile.provider, data)
      .then(() => {
        return this._reloadUserRightsIfRequires(signInResult);
      })
      .then(() => {
        return {
          nextStep: 'redirect',
          redirectUrl: signInResult.redirectUrl, // TODO: remove usage of the url
        } as ProcessRedirectResultRedirect;
      })
      .catch(err => {
        console.error(err);
        if (err?.error?.todo === REGISTER_SOCIAL_ACCOUNT) {
          return this.miAuthService
            .signUpSocial(signInResult.profile.provider, data)
            .then(() => {
              // save AO profile data
              return this._reloadUserRightsIfRequires(signInResult);
            })
            .then(() => {
              // set name from social login data
              const profileData: UpdateUserProfileData =
                this._extractNameFromSocialProfile(signInResult.profile);
              if (
                profileData &&
                (profileData.firstName || profileData.lastName)
              ) {
                return this.miAuthService.updateProfile(
                  this.appConfig.authentication.clientId,
                  profileData
                );
              }
            })
            .then(() => {
              return {
                nextStep: 'social-signup',
              } as ProcessRedirectResultSocialSignUp;
            })
            .catch(signUpError => {
              console.error(signUpError);
              return Promise.reject(signUpError);
            });
        }
        return Promise.reject(err);
      });
  }

  signUp(provider: AuthProvider): Promise<RedirectResult> {
    switch (provider) {
      case AuthProvider.AO:
        return this._signUpForAO();
      default:
        return Promise.reject('provider is not supported: ' + provider);
    }
  }

  _signUpForAO(): Promise<RedirectResult> {
    return this.aoAuthService.signUp();
  }

  _signInForMI(authData: SignInData): Promise<SignInResult> {
    if (authData?.username && authData?.password) {
      return this.miAuthService
        .signIn(authData.username, authData.password)
        .then(res => {
          if (res.hasToMigrate) {
            return { nextStep: SignInNextStep.MIGRATE };
          }

          return { nextStep: SignInNextStep.WORK };
        });
    }
    return Promise.reject('username and password have to be provided');
  }

  get isWebPlatform(): boolean {
    return (
      this.appConfig.platform === Platform.BROWSER ||
      this.appConfig.platform === Platform.PWA
    );
  }

  _signInForAO(redirectUrl: string): Promise<SignInResult> {
    return this.aoAuthService.signIn(redirectUrl).then(res => {
      if (this.isWebPlatform) {
        return Promise.resolve({
          nextStep: SignInNextStep.REDIRECT,
          redirectUrl: res.redirectUrl,
        });
      } else {
        // device
        if (res && 'type' in res) {
          if (res.type === 'ao-signin') {
            return this._processAOSignIn(res).then(signInRes => {
              if (signInRes.nextStep === 'redirect') {
                return {
                  nextStep: SignInNextStep.WORK,
                };
              } else if (signInRes.nextStep === 'social-signup') {
                return {
                  nextStep: SignInNextStep.MIGRATE,
                };
              }
              return null;
            });
          }
        }
      }
    });
  }

  openAoLink(url: string): Promise<void> {
    return this.aoAuthService.openAoLink(url);
  }
}

export const forTesting = {
  REGISTER_SOCIAL_ACCOUNT,
};
