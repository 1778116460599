import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';

// mocks
import { PublicProfile } from 'src/app/services/yeti-protocol/public-profile';

// services
import { PeopleListSuccessResponse } from 'src/app/services/yeti-protocol/clinical-expert';
import { AppNavController } from 'src/app/services/app-nav-controller.service';
import { RecommendedPeopleStrategy } from 'src/app/services/recommended-people/recommended-people';
import { ClinicalExpertsService } from 'src/app/services/clinical-experts.service';
import { Store } from '@ngxs/store';
import { PeopleYouMayKnow } from 'src/app/state/people-you-may-know/people-you-may-know.actions';
import { TRACKING_SERVICE, TrackingService } from 'src/app/services/tracking/tracking.model';
import { ActionSource, ActionTracked } from 'src/app/services/yeti-protocol/tracking';

@Component({
  selector: 'app-coparticipants-list-dialog',
  templateUrl: './coparticipants-list.dialog.component.html',
  styleUrls: ['./coparticipants-list.dialog.component.scss'],
})
export class CoparticipantsListDialogComponent implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  @Input() titleTranslationKey: string;
  @Input() recommendedPeopleStrategy: RecommendedPeopleStrategy;
  @Input() showXButton: boolean;
  @Input() source: string;
  @Input() clickTrackingObjId: string;

  coparticipantsList: Array<PublicProfile> = new Array<PublicProfile>();
  resultList: Array<PublicProfile> = new Array<PublicProfile>();
  totalItemsCount: number;
  private readonly start = 0;
  private readonly count = 15;
  private lastFetchLength: number;

  constructor(
    private modalController: ModalController,
    private appNavController: AppNavController,
    private clinicalExpertsService: ClinicalExpertsService,
    private store: Store,
    @Inject(TRACKING_SERVICE) private trackingService: TrackingService,
  ) { }

  ngOnInit(): void {
    this.getRecommendedPeople(this.start, false);
  }

  loadMoreRecommendedCoparticipants(_event: Event): void {
    if (this.lastFetchLength < this.count) {
      this.disableInfiniteScroll(true);
      return;
    }
    const start = Math.floor(this.coparticipantsList.length / this.count);
    this.getRecommendedPeople(start, true);
  }

  onClose(): void {
    this.modalController.dismiss();
  }

  onOpenPublicUserProfile(userId: string): void {
    this.onClose();
    this.appNavController.openPublicUserProfile(userId);
  }

  private getRecommendedPeople(start: number, hideInfiniteScroll: boolean): void {
    this.recommendedPeopleStrategy?.getRecommendedPeople(start, this.count)
      .then((response: PeopleListSuccessResponse) => {
        this.resultList = response?.result;
        this.lastFetchLength = this.resultList?.length;
        this.totalItemsCount = response.totalItemsCount;
        this.coparticipantsList = [...this.coparticipantsList, ...this.resultList];
        if (hideInfiniteScroll) {
          this.infiniteScroll.complete();
        }
      });
  }

  private disableInfiniteScroll(value: boolean): void {
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = value;
    }
  }

  ignorePerson(person: PublicProfile): void {

    const personIndex = this.coparticipantsList?.findIndex(p => p?.userId === person?.userId);

    if (personIndex !== -1) {

      this.trackGenericClickedAction(ActionTracked.ignorePerson);

      this.clinicalExpertsService.ignoreRecommendedContact(person?.userId, this.source).then(() => {

        this.coparticipantsList.splice(personIndex, 1);
        this.store.dispatch(new PeopleYouMayKnow.RemovePerson(person));

        if (!this.coparticipantsList.length) {
          this.getRecommendedPeople(this.start, false);
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }

  trackGenericClickedAction(objType: string): Promise<void> {

    if (!this.clickTrackingObjId) {
      return;
    }

    const objId = this.clickTrackingObjId;
    const objectTitle = this.clickTrackingObjId;

    return this.trackingService.trackGenericClickedAction(objId, objType, objectTitle,
      { source: this.source || ActionSource.unspecified })
      .catch(_err => {
        console.error(`Could not track: ${objType} click action.`);
      });
  }
}
