import { Injectable, Inject } from '@angular/core';
import { OrcIdAuthDeviceServiceInterface } from './orcid-auth-device.service.interface';
import { BrowserTab } from '@awesome-cordova-plugins/browser-tab/ngx';
import { InAppBrowserColors, Platform } from '../../../../config/config.model';
import appConfig from '../../../../config/config';
import { AppTranslationService } from '../../app-translation.service';
import { ActionTracked, LinkOrcIdUrlOpenedTrackingParam, TrackingRequest } from '../../yeti-protocol/tracking';
import { TRACKING_SERVICE, TrackingService } from '../../tracking/tracking.model';

export interface OrcIdAuthDeviceAndroidServiceConfig {
  inAppBrowserColors: InAppBrowserColors;
}

@Injectable()
export class OrcIdAuthDeviceAndroidService implements OrcIdAuthDeviceServiceInterface {

  config: OrcIdAuthDeviceAndroidServiceConfig = appConfig;

  constructor(
    private browserTab: BrowserTab,
    protected appTranslationService: AppTranslationService,
    @Inject(TRACKING_SERVICE) protected trackingService: TrackingService
  ) { }

  signIn(orcIdOAuthUrl: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.browserTab.isAvailable().then(available => {
        if (available) {
          this.browserTab.openUrl(orcIdOAuthUrl).then(() => {
            console.log('chrome tab is opened')
          }).catch(err => {
            this._trackErrorOpeningOrcIdLink(err);
            console.error(err);
            reject(err);
          });
        } else {
          console.error('BrowserTab not available!')
          resolve();
        }
      })
    });
  }

  _trackErrorOpeningOrcIdLink(error: Error): void {
    const paramsToTrack: LinkOrcIdUrlOpenedTrackingParam = {
      objectId: 'linkOrcIdUrlOpened',
      objectType: 'linkOrcIdUrlOpened',
      objectTitle: 'Error',
      error: JSON.stringify(error),
      platform: Platform.ANDROID
    };
    const trackData: TrackingRequest = {
      action: ActionTracked.linkOrcIdUrlOpened,
      params: paramsToTrack
    };

    this.trackingService.track(trackData);
  }
}

