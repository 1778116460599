import { Inject, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { toAuthRequestParams } from './auth/logic/auth-logic.utils';
import {
  ClinicalExpertsListRequestParams,
  ClinicalExpertsListResponse,
  ClinicalExpertsListSuccessResponse,
  IgnoreRecommendedContactResponse,
  PeopleListRequestParams,
  PeopleListResponse,
  PeopleListSuccessResponse
} from './yeti-protocol/clinical-expert';

// services
import { AuthService } from './auth/auth.service';
import { SchemaValidatorService } from './schema-validator.service';
import { PublicProfile } from './yeti-protocol/public-profile';
import { ErrorResponse } from './yeti-protocol/error';

import appConfig from 'src/config/config';
import { ActionSource } from './yeti-protocol/tracking';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';

export interface ClinicalExpertsServiceConfig {
  serverUrl: string;
  serverUrlCoparticipants: string;
  serverUrlPeopleToFollow: string;
  serverUrlRecommendedContacts: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClinicalExpertsService {
  config: ClinicalExpertsServiceConfig = {
    serverUrl: `${appConfig.serverUrlIonic}profile/verification/list/promoted/true`,
    serverUrlCoparticipants: `${appConfig.backendUrlIonic}recommendation/users`,
    serverUrlPeopleToFollow: `${appConfig.backendUrlIonic}recommendation/experts`,
    serverUrlRecommendedContacts: `${appConfig.backendUrlIonic}recommendation/contacts`,
  }
  private defaultStart = 0;
  private defaultCount = 10;

  constructor(
    private authService: AuthService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private schemaValidator: SchemaValidatorService
  ) { }

  getRecommendedPeopleToFollow(start: number = this.defaultStart, count: number = this.defaultCount): Promise<Array<PublicProfile>> {
    const params: PeopleListRequestParams = {
      appId: this._appId,
      start,
      count
    }

    const getRequestUrl = this.config.serverUrlPeopleToFollow;

    return this.fetchPeople(params, getRequestUrl)
      .then((response: PeopleListResponse) => {
        if (response as PeopleListSuccessResponse) {
          return (response as PeopleListSuccessResponse).result;
        }
        return Promise.reject(response as ErrorResponse)
      });
  }

  getFollowedPeople(start: number = this.defaultStart, count: number = this.defaultCount): Promise<Array<PublicProfile>> {
    const params: ClinicalExpertsListRequestParams = {
      onlyFollowed: true,
      appId: this._appId,
      start,
      count
    }

    return this.fetchClinicalExperts(params)
      .then((response: ClinicalExpertsListResponse) => {
        if (response as ClinicalExpertsListSuccessResponse) {
          return (response as ClinicalExpertsListSuccessResponse).result;
        }
        return Promise.reject(response as ErrorResponse);
      });
  }

  getMixedPeople(start: number = this.defaultStart, count: number = this.defaultCount): Promise<Array<PublicProfile>> {
    const params: PeopleListRequestParams = {
      mixed: true,
      appId: this._appId,
      start,
      count
    }

    const getRequestUrl = this.config.serverUrlPeopleToFollow;
    return this.fetchPeople(params, getRequestUrl)
      .then((response: PeopleListResponse) => {
        return this._processPeopleListResponse(response);
      });
  }

  getRecommendedCoparticipants(start: number = this.defaultStart, count: number = this.defaultCount):
    Promise<PeopleListSuccessResponse> {
    const params: PeopleListRequestParams = {
      appId: this._appId,
      start,
      count
    }

    const getRequestUrl = this.config.serverUrlCoparticipants;

    return this.fetchPeople(params, getRequestUrl)
      .then((response: PeopleListResponse) => {
        if (response as PeopleListSuccessResponse) {
          return response as PeopleListSuccessResponse;
        }
        return Promise.reject(response as ErrorResponse);
      });
  }

  getRecommendedContacts(start: number = this.defaultStart, count: number = this.defaultCount):
    Promise<PeopleListSuccessResponse> {

    const params: PeopleListRequestParams = {
      appId: this._appId,
      start,
      count
    }

    const getRequestUrl = this.config.serverUrlRecommendedContacts;

    return this.fetchPeople(params, getRequestUrl)
      .then((response: PeopleListResponse) => {
        if (response as PeopleListSuccessResponse) {
          return response as PeopleListSuccessResponse;
        }
        return Promise.reject(response as ErrorResponse);
      });
  }

  ignoreRecommendedContact(userId: string, source?: string):
    Promise<IgnoreRecommendedContactResponse> {

    const url = `${this.config.serverUrlRecommendedContacts}/ignore/${userId}`;

    const requestParams: any = {};

    if (source) {
      requestParams.source = source;
    }

    return this.authService.asserIsSignedIn()
      .then(() => {
        return firstValueFrom(
          this.authService.securePost<any, IgnoreRecommendedContactResponse>(url, toAuthRequestParams(requestParams), {})
            .pipe(
              this.schemaValidator.isValidOperator('IgnoreRecommendedContactResponse')
            ));
      });
  }

  _processPeopleListResponse(response: PeopleListResponse): Array<PublicProfile> {
    if (response as PeopleListSuccessResponse) {
      return (response as PeopleListSuccessResponse).result;
    }
    return [];
  }

  followUnfollow(clinicalExpertId: string, follow: boolean, source: ActionSource = ActionSource.unspecified): Promise<boolean> {
    // TODO: add request response data definition
    return this.updateClinicalExpertFollow(clinicalExpertId, follow, source)
      .then((data: any) => {
        return !!data.success;
      }).catch(error => {
        console.log('error: ' + error);
        return Promise.reject(error);
      });
  }

  updateClinicalExpertFollow(clinicalExpertId: string, follow: boolean, source: ActionSource = ActionSource.unspecified): Promise<any> {
    if (clinicalExpertId) {

      let url = `${appConfig.serverUrlIonic}following/follow/${clinicalExpertId}/${follow}`;

      const body = {
        appId: this._appId
      }

      if (source && source !== ActionSource.unspecified) {
        url += '?source=' + source;

        (body as any).source = source;
      }

      return firstValueFrom(this.authService.securePost(url, body,
        { params: { appId: this._appId } }));
    }
    return Promise.resolve();
  }

  private fetchClinicalExperts(params: ClinicalExpertsListRequestParams): Promise<ClinicalExpertsListResponse> {
    return this.authService.asserIsSignedIn()
      .then(() => {
        const getRequestUrl = this.config.serverUrl;
        return firstValueFrom(
          this.authService.secureGet<ClinicalExpertsListResponse>(getRequestUrl, { params: toAuthRequestParams(params) })
            .pipe(
              this.schemaValidator.isValidOperator('ClinicalExpertsListResponse')
            ));
      });
  }

  private fetchPeople(params: PeopleListRequestParams, getRequestUrl: string): Promise<PeopleListResponse> {
    return this.authService.asserIsSignedIn()
      .then(() => {
        return firstValueFrom(this.authService.secureGet<PeopleListResponse>(getRequestUrl, { params: toAuthRequestParams(params) })
          .pipe(
            this.schemaValidator.isValidOperator('PeopleListResponse')
          ));
      });
  }

  get _appId(): string {
    return this.contextService.currentContext.key;
  }
}
