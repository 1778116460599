// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-internet-connection-page-container {
  width: 100%;
  height: 100%;
}
.no-internet-connection-page-container ion-content {
  --padding-top: 50px;
  --padding-end: 40px;
  --padding-bottom: 35px;
  --padding-start: 40px;
  --background: var(--mi-grey-color-20);
}
.no-internet-connection-page-container ion-content .wrapper {
  max-width: 892px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.no-internet-connection-page-container ion-content .wrapper .image-container {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-internet-connection-page-container ion-content .wrapper .image-container ion-img {
  max-width: 658px;
}
.no-internet-connection-page-container ion-content .wrapper .title-container .title {
  font-size: var(--font-size-large-1);
  font-weight: var(--font-weight-medium);
  color: var(--mi-grey-color-16);
  margin: 0;
  text-align: center;
}
.no-internet-connection-page-container ion-content .wrapper .text-container {
  padding-bottom: 40px;
}
.no-internet-connection-page-container ion-content .wrapper .text-container .text {
  font-size: var(--font-size-large-1);
  font-weight: var(--font-weight-regular);
  color: var(--mi-grey-color-16);
  margin: 0;
  text-align: center;
}
.no-internet-connection-page-container ion-content .wrapper .button-container {
  max-width: 400px;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  row-gap: 23px;
  justify-items: center;
}
.no-internet-connection-page-container ion-content .wrapper .button-container .try-again-btn {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
