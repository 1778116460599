// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --max-main-column-width: 530px;
}

.no-internet-connection {
  background-color: var(--mi-red-color-3);
  width: 100%;
  height: 48px;
  text-align: center;
  color: var(--mi-white-color);
  font-size: var(--font-size-large);
  max-width: var(--max-main-column-width);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  align-self: center;
}
.no-internet-connection.isDesktop {
  margin-right: 15px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
