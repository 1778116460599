// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: absolute;
  width: 100%;
  height: 100%;
}
:host.mobile-editor ::ng-deep .tui-image-editor-menu-draw .tui-image-editor-range,
:host.mobile-editor ::ng-deep .tui-image-editor-menu-shape .tui-image-editor-range,
:host.mobile-editor ::ng-deep .tui-image-editor-menu-text .tui-image-editor-range {
  display: none;
}

::ng-deep .tui-image-editor-main-container .tui-image-editor-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  bottom: 0;
}
::ng-deep .tui-image-editor-main-container .tui-image-editor-main .tui-image-editor-submenu {
  flex-grow: 0;
  flex-shrink: 0;
  height: 100px;
  display: contents !important;
}
::ng-deep .tui-image-editor-main-container .tui-image-editor-main .tui-image-editor-wrap {
  position: relative;
  flex-grow: 0;
  flex-shrink: 1;
}
::ng-deep .tui-image-editor-main-container .tui-image-editor-main .tui-image-editor-wrap .tui-image-editor-size-wrap {
  display: block;
}
::ng-deep .tui-image-editor-main-container .tui-image-editor-main .tui-image-editor-wrap .tui-image-editor-size-wrap .tui-image-editor-align-wrap {
  display: block;
}
::ng-deep .tui-image-editor-main-container .tui-image-editor-main .tui-image-editor-wrap .tui-image-editor-size-wrap .tui-image-editor-align-wrap .tui-image-editor-canvas-container {
  top: 0;
}
::ng-deep .tui-image-editor-container.top .tui-image-editor-main {
  top: 0;
  height: 100%;
}
::ng-deep .tui-image-editor-container {
  overflow: hidden;
}
::ng-deep .tui-image-editor-container .tui-image-editor-wrap {
  overflow: hidden;
  overflow-y: auto;
}
::ng-deep .tui-image-editor-container .tui-image-editor-wrap .tui-image-editor {
  top: 0 !important;
}
::ng-deep .tui-image-editor-container .tui-image-editor-submenu {
  opacity: 0.75;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .tui-image-editor-menu-crop {
  padding-top: 4px;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .tui-image-editor-submenu {
  height: 90px;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .tui-image-editor-submenu .preset-5-4,
::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .tui-image-editor-submenu .preset-7-5 {
  display: none;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .tui-image-editor-submenu .tie-crop-preset-button .preset {
  margin-bottom: 4px;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .action .tui-image-editor-button {
  padding: 0 8px 0 2px;
  border-radius: 3px;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .action .tui-image-editor-button.apply .svg_ic-menu .normal,
::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .action .tui-image-editor-button.apply .svg_ic-menu .active, ::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .action .tui-image-editor-button.cancel .svg_ic-menu .normal,
::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .action .tui-image-editor-button.cancel .svg_ic-menu .active {
  stroke: var(--mi-inputs-color);
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .action .tui-image-editor-button.apply label, ::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .action .tui-image-editor-button.cancel label {
  color: var(--mi-inputs-color);
  font-weight: bold;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .action .tui-image-editor-button.apply {
  background-color: var(--mi-hover-color);
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-crop .action .tui-image-editor-button.cancel {
  background-color: var(--mi-color-text-lite);
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-text .tui-image-editor-submenu {
  height: 90px;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-text .tui-image-editor-submenu .tie-text-effect-button,
::ng-deep .tui-image-editor-container .tui-image-editor-menu-text .tui-image-editor-submenu .tie-text-align-button {
  display: none;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-text .tui-image-editor-submenu .tui-image-editor-range-wrap {
  display: inline-block;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-text .tui-image-editor-submenu .tui-image-editor-partition {
  display: none;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-text .tui-image-editor-submenu .tui-image-editor-partition.only-left-right {
  display: inline-block;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-icon .tui-image-editor-submenu {
  height: 90px;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-shape .tui-image-editor-submenu {
  height: 90px;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-shape .tui-image-editor-submenu .tui-image-editor-range-wrap {
  display: none;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-draw .tui-image-editor-submenu {
  height: 90px;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-draw .tui-image-editor-submenu .tui-image-editor-button.line {
  display: none;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-draw .tui-image-editor-submenu .tui-image-editor-range-wrap {
  display: inline-block;
}
::ng-deep .tui-image-editor-container .tui-image-editor-menu-draw .tui-image-editor-submenu .tui-image-editor-partition.only-left-right {
  display: inline-block;
}
::ng-deep .tui-image-editor-container .tui-image-editor-header,
::ng-deep .tui-image-editor-container .tui-image-editor-controls,
::ng-deep .tui-image-editor-container .tui-image-editor-controls-logo,
::ng-deep .tui-image-editor-container .tui-image-editor-controls-buttons,
::ng-deep .tui-image-editor-container .tui-image-editor-help-menu {
  display: none !important;
}
::ng-deep .tui-image-editor-main-container .tui-image-editor-menu-icon .tui-image-editor-submenu .tie-icon-add-button [data-icontype=icon-arrow-3],
::ng-deep .tui-image-editor-main-container .tui-image-editor-menu-icon .tui-image-editor-submenu .tie-icon-add-button [data-icontype=icon-star],
::ng-deep .tui-image-editor-main-container .tui-image-editor-menu-icon .tui-image-editor-submenu .tie-icon-add-button [data-icontype=icon-star-2],
::ng-deep .tui-image-editor-main-container .tui-image-editor-menu-icon .tui-image-editor-submenu .tie-icon-add-button [data-icontype=icon-polygon],
::ng-deep .tui-image-editor-main-container .tui-image-editor-menu-icon .tui-image-editor-submenu .tie-icon-add-button [data-icontype=icon-location],
::ng-deep .tui-image-editor-main-container .tui-image-editor-menu-icon .tui-image-editor-submenu .tie-icon-add-button [data-icontype=icon-heart],
::ng-deep .tui-image-editor-main-container .tui-image-editor-menu-icon .tui-image-editor-submenu .tie-icon-add-button [data-icontype=icon-bubble],
::ng-deep .tui-image-editor-main-container .tui-image-editor-menu-icon .tui-image-editor-submenu .tie-icon-add-button .tui-image-editor-partition:last-of-type {
  display: none;
}
::ng-deep .tui-image-editor-submenu .tui-image-editor-menu-icon .tui-image-editor-submenu-item .tui-image-editor-partition {
  display: none !important;
}
::ng-deep .tui-image-editor-submenu .tui-image-editor-menu-icon .tui-image-editor-submenu-item .tie-icon-add-button .tui-image-editor-button:not([data-icontype]) {
  display: none !important;
}
::ng-deep .color-picker-control .color-picker ul li {
  float: left;
}
::ng-deep .color-picker-control .color-picker ul li input {
  width: 36px;
  height: 36px;
}
::ng-deep .color-picker-control .color-picker .tui-colorpicker-palette-container div {
  display: none;
}
@media all and (max-width: 700px) {
  ::ng-deep .tui-image-editor-partition {
    display: none !important;
    height: 0;
  }
  ::ng-deep .tui-image-editor-submenu-item li {
    display: block !important;
    padding-bottom: 10px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
